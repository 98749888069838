import { useEffect, useState } from "react";
import { Controller, UseFormWatch } from "react-hook-form";
import tw from "twin.macro";

import {
  language_enum,
  offer_goal_enum,
  platform_enum,
} from "../../__generated__/graphql";
import Editor from "../../common/editor2/Editor";
import EditorSelector from "../../common/editor2/EditorSelector";
import { isContentEmpty } from "../../common/editor2/lib";
import FieldCheckboxInput from "../../common/form/FieldCheckboxInput";
import FieldError from "../../common/form/FieldError";
import FieldHint from "../../common/form/FieldHint";
import FieldInput from "../../common/form/FieldInput";
import FieldLabel from "../../common/form/FieldLabel";
import FieldRow from "../../common/form/FieldRow";
import FieldRowBlock from "../../common/form/FieldRowBlock";
import FieldSet from "../../common/form/FieldSet";
import ImageInput from "../../common/form/input/ImageInput";
import SelectInput from "../../common/form/input/SelectInput";
import TagsInput from "../../common/form/input/TagsInput";
import TextInput from "../../common/form/input/TextInput";
import useTranslatableForm, {
  TranslatedForms,
} from "../../common/form/useTranslatableForm";
import HelpIcon from "../../common/HelpIcon";
import MetadataInput from "../../common/MetadataInput";
import { useTranslations } from "../../common/translations/TranslationsProvider";
import useAccountFeatures from "../../common/useAccountFeatures";
import useFocusFirstEmptyInput from "../../common/useFocusFirstEmptyInput";
import ConversionOfferFormPartial from "../conversion/ConversionOfferFormPartial";
import LanguageRadio from "../flow/edit/LanguageRadio";
import { useUpsell } from "../upgrade-account/UpsellProvider";
import getDefaultFormValues from "./forms/lib/getDefaultFormValues";
import getTranslatableFields from "./forms/lib/getTranslatableFields";
import { CustomOfferFormValues, OfferFormValues } from "./forms/types";
import useValidateHeadline from "./lib/useValidateHeadline";
import useValidateName from "./lib/useValidateName";
import validateMetadata from "./lib/validateMetadata";

interface CustomerOfferFormProps {
  goal?: offer_goal_enum;
  onChange: (forms: TranslatedForms<CustomOfferFormValues>) => void;
  onSubmit: (forms: TranslatedForms<CustomOfferFormValues>) => void;
  initialValues?: TranslatedForms<CustomOfferFormValues>;
  onChangeEditingLanguage: (language: language_enum) => void;
  isSubmitting: boolean;
  tags: string[];
  platform: platform_enum;
}

const CustomOfferForm: React.FunctionComponent<CustomerOfferFormProps> = ({
  onChange,
  onSubmit,
  initialValues,
  onChangeEditingLanguage,
  isSubmitting,
  goal = offer_goal_enum.retention,
  tags,
  platform,
}) => {
  const { defaultLanguage, enabledLanguages } = useTranslations();
  const defaultValues =
    initialValues ||
    getDefaultFormValues<CustomOfferFormValues>({
      type: "custom",
      enabledLanguages,
      platform,
    });

  const {
    register,
    formState,
    handleSubmit,
    control,
    editingLanguage,
    setEditingLanguage,
    forms,
    trigger,
    watch,
    setValue,
  } = useTranslatableForm<CustomOfferFormValues>({
    defaultValues,
    initialLanguage: defaultLanguage,
    languages: enabledLanguages,
    defaultLanguage,
    translatableFields: getTranslatableFields("custom"),
  });
  const values = watch();
  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  useFocusFirstEmptyInput(formRef);
  const validateName = useValidateName(formState.dirtyFields.name || false);
  const image = watch("image");
  const validateHeadline = useValidateHeadline(image);
  const { features } = useAccountFeatures();
  const { enabled: isFreeMode } = useUpsell();

  useEffect(() => {
    onChange(forms);
  }, [forms, onChange]);

  return (
    <form
      id="create-offer"
      onChange={() => onChange(forms)}
      ref={(ref) => setFormRef(ref)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <fieldset disabled={isSubmitting}>
        <input {...register("type")} type="hidden" value="custom" />
        <FieldRow>
          <FieldLabel>
            <label htmlFor="name">Name</label>
          </FieldLabel>
          <FieldInput>
            <TextInput
              {...register("name", {
                required: true,
                validate: validateName,
              })}
              id="name"
              width="full"
              fieldError={formState.errors.name}
            />
            <FieldError error={formState.errors.name} />
          </FieldInput>
        </FieldRow>
        <FieldRow>
          <FieldLabel>Tags</FieldLabel>
          <FieldInput>
            <Controller
              control={control}
              name="tags"
              render={({ field }) => (
                <TagsInput
                  tags={tags}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldInput>
        </FieldRow>
        {goal === offer_goal_enum.conversion ? (
          <ConversionOfferFormPartial
            register={register}
            watch={watch as UseFormWatch<OfferFormValues>}
            control={control}
            setValue={setValue}
            isDirty={formState.isDirty}
          />
        ) : (
          <FieldRow>
            <FieldLabel>
              <label htmlFor="style">Style</label>
            </FieldLabel>
            <FieldInput>
              <SelectInput
                {...register("style")}
                id="style"
                defaultValue="modal"
              >
                <option value="modal">Modal</option>
                <option value="step">Step</option>
              </SelectInput>
              <FieldHint>
                {values.style === "modal" ? (
                  <>Offer will be shown in a pop-up modal dialog.</>
                ) : (
                  <>Offer will be shown as a full flow step.</>
                )}
              </FieldHint>
            </FieldInput>
          </FieldRow>
        )}
        <FieldRow>
          <FieldLabel>
            <label>
              Allow override{" "}
              <HelpIcon content="By default, an offer will not be displayed to a subscriber if they have previously accepted another offer. This setting will allow this offer to override any previously accepted offers." />
            </label>
          </FieldLabel>
          <FieldCheckboxInput>
            <input
              {...register("allowOverride")}
              id="allowOverride"
              type="checkbox"
            />
            <label htmlFor="allowOverride">Override other offers</label>
          </FieldCheckboxInput>
        </FieldRow>

        <FieldSet title={`Offer ${values.style}`}>
          {values.style === "modal" && (
            <FieldRow>
              <FieldLabel>
                <label>Banner</label>
              </FieldLabel>
              <FieldCheckboxInput>
                <input
                  {...register("showBanner")}
                  id="showBanner"
                  type="checkbox"
                />
                <label htmlFor="showBanner">Show "Special Offer" banner</label>
              </FieldCheckboxInput>
            </FieldRow>
          )}
          {goal === offer_goal_enum.conversion && values.timerMode !== "" && (
            <FieldRow>
              <FieldLabel>
                <label>Countdown timer</label>
              </FieldLabel>
              <FieldCheckboxInput>
                <input
                  {...register("countdownTimer")}
                  id="countdownTimer"
                  type="checkbox"
                />
                <label htmlFor="countdownTimer">Show countdown timer</label>
              </FieldCheckboxInput>
            </FieldRow>
          )}
          {enabledLanguages.length > 1 && (
            <LanguageRadio
              value={editingLanguage}
              languages={enabledLanguages}
              defaultLanguage={defaultLanguage}
              onChange={(language) => {
                setEditingLanguage(language);
                onChangeEditingLanguage(language);
              }}
            />
          )}
          <FieldRow>
            <FieldLabel>
              <label>Headline</label>
            </FieldLabel>
            <FieldInput>
              <TextInput
                {...register("headline", { validate: validateHeadline })}
                id="headline"
                width="full"
                fieldError={formState.errors.headline}
              />
              <FieldError error={formState.errors.headline} />
            </FieldInput>
          </FieldRow>
          <FieldRow>
            <FieldLabel>
              <label>Image</label>
            </FieldLabel>
            <FieldInput>
              <Controller
                control={control}
                name="image"
                render={({ field }) => (
                  <ImageInput
                    value={field.value}
                    onChange={(logoUrl) => {
                      field.onChange(logoUrl);
                      if (formState.isSubmitted) {
                        trigger();
                      }
                    }}
                  />
                )}
              />
            </FieldInput>
          </FieldRow>
          <FieldRow style={{ display: "block" }}>
            <FieldLabel>
              <label>Content</label>
            </FieldLabel>
            <Controller
              control={control}
              name="content"
              render={({ field }) => {
                const form = forms[editingLanguage];
                if (!form) {
                  return <></>;
                }

                return (
                  <EditorSelector
                    initialValueKey={editingLanguage}
                    isOfferContent
                    hasCustomProperties={!!features.custom_properties}
                    format={form.contentFormat}
                    value={form.content}
                    onChange={(value) => {
                      field.onChange(value);
                      onChange({
                        ...forms,
                        [editingLanguage]: {
                          ...forms[editingLanguage],
                          content: value,
                        },
                      });
                    }}
                  />
                );
              }}
            />
          </FieldRow>
          <FieldRow>
            <FieldLabel>
              <label>Button text</label>
            </FieldLabel>
            <FieldInput>
              <TextInput
                {...register("buttonText", { required: true })}
                id="buttonText"
                width="md"
                fieldError={formState.errors.buttonText}
              />
            </FieldInput>
          </FieldRow>
        </FieldSet>

        {goal === "conversion" && (
          <FieldSet title="Confirmation modal">
            <FieldRow>
              <FieldLabel>
                <label>Enabled</label>
              </FieldLabel>
              <FieldCheckboxInput>
                <input
                  {...register("confirmationEnabled")}
                  id="confirmationEnabled"
                  type="checkbox"
                />
                <label htmlFor="confirmationEnabled">
                  Show confirmation modal
                </label>
              </FieldCheckboxInput>
            </FieldRow>
            <fieldset
              css={!values.confirmationEnabled ? tw`hidden` : undefined}
            >
              <FieldRowBlock>
                <FieldLabel>
                  <label>Content</label>
                </FieldLabel>
                <Controller
                  control={control}
                  name="confirmationContent"
                  render={({ field }) => (
                    <Editor
                      key={editingLanguage}
                      height="10rem"
                      tagsEnabled={false}
                      linksEnabled={false}
                      listsEnabled={false}
                      imagesEnabled={false}
                      videosEnabled={false}
                      initialValue={field.value}
                      initialValueKey={editingLanguage}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      fieldError={formState.errors.confirmationContent}
                    />
                  )}
                  rules={{
                    validate: (value) =>
                      values.confirmationEnabled
                        ? !!value && !isContentEmpty(JSON.parse(value))
                          ? true
                          : "Content is required."
                        : true,
                  }}
                />
              </FieldRowBlock>
              <FieldRow>
                <FieldLabel>
                  <label>Confirm button text</label>
                </FieldLabel>
                <FieldInput>
                  <TextInput
                    {...register("confirmationConfirmButtonText", {
                      validate: (value) =>
                        values.confirmationEnabled
                          ? !!value
                            ? true
                            : "Confirm button text is required."
                          : true,
                    })}
                    width="md"
                    fieldError={formState.errors.confirmationConfirmButtonText}
                    disabled={isFreeMode}
                  />
                </FieldInput>
              </FieldRow>
              <FieldRow>
                <FieldLabel>
                  <label>Cancel button text</label>
                </FieldLabel>
                <FieldInput>
                  <TextInput
                    {...register("confirmationCancelButtonText", {
                      validate: (value) =>
                        values.confirmationEnabled
                          ? !!value
                            ? true
                            : "Cancel button text is required."
                          : true,
                    })}
                    width="md"
                    fieldError={formState.errors.confirmationCancelButtonText}
                    disabled={isFreeMode}
                  />
                </FieldInput>
              </FieldRow>
            </fieldset>
          </FieldSet>
        )}

        <FieldSet title="Metadata">
          <FieldRow>
            <FieldInput>
              <Controller
                control={control}
                name="metadata"
                render={({ field }) => (
                  <MetadataInput
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
                rules={{
                  validate: validateMetadata,
                }}
              />
              <FieldError error={formState.errors.metadata} />
            </FieldInput>
          </FieldRow>
        </FieldSet>
      </fieldset>
    </form>
  );
};

export default CustomOfferForm;
