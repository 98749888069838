import { Temporal } from "@js-temporal/polyfill";
import { forwardRef, useEffect, useState } from "react";
import tw, { css, styled } from "twin.macro";

import {
  coupon_duration_enum,
  coupon_duration_interval_enum,
  FlowOfferFragment,
  FlowOfferPauseSubscriptionOptionFragment,
  FlowSubscriptionFragment,
  language_enum,
  offer_change_plan_change_at_enum,
  offer_coupon_type_enum,
  offer_goal_enum,
  offer_modify_subscription_modify_at_enum,
  offer_pause_subscription_interval_enum,
  offer_pause_subscription_option_type_enum,
  offer_style_enum,
  offer_timer_mode_enum,
  offer_type_enum,
  PauseReason,
  translation_value_format_enum,
} from "../../__generated__/graphql";
import placeholder from "../../assets/placeholder.svg";
import { flowButtonCss, flowModalCss } from "../public/flow/DefaultStyles";
import mapFlowText from "../public/flow/lib/mapFlowText";
import OfferModal from "../public/flow/OfferModal";
import Offer from "../public/flow/steps/Offer";
import { OfferFormValues } from "./forms/types";

const Container = styled.div<{ showTimer: boolean }>`
  ${tw`flex justify-center max-h-full`}
  ${(props) => flowModalCss(props.showTimer)}
  ${flowButtonCss()}
`;

interface OfferPreviewProps {
  formValues: OfferFormValues | undefined;
  onImageLoad?: () => void;
  previewMode?: boolean;
  goal?: offer_goal_enum;
  showTimer?: boolean;
  swappableProducts?: FlowSubscriptionFragment;
  pauseReasons?: PauseReason[];
  autopilot?: boolean;
  showDeclineButton?: boolean;
  displayMode?: "panel" | "modal" | "card";
}

const OfferPreview: React.FunctionComponent<OfferPreviewProps> = ({
  formValues,
  onImageLoad,
  previewMode = true,
  goal = offer_goal_enum.retention,
  showTimer = false,
  swappableProducts = null,
  pauseReasons,
  showDeclineButton = true,
  displayMode = "panel",
}) => {
  const [offer, setOffer] = useState<FlowOfferFragment>();
  const flowText = mapFlowText([], "published");

  useEffect(() => {
    if (!formValues) {
      return;
    }
    const now = new Date().toISOString();
    const content = formValues.content
      ? typeof formValues.content === "object"
        ? formValues.content
        : JSON.parse(formValues.content)
      : undefined;

    switch (formValues.type) {
      case "coupon":
        setOffer({
          __typename: "offer",
          id: 1,
          token: "offer",
          type: offer_type_enum.coupon,
          name: formValues.name,
          show_banner: formValues.showBanner,
          image_url: formValues.image,
          created_at: now,
          updated_at: now,
          headline_translation: {
            __typename: "translation",
            id: 1,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 1,
                language: language_enum.en_us,
                value: formValues.headline,
                format: translation_value_format_enum.text,
              },
            ],
          },
          content_translation: {
            __typename: "translation",
            id: 2,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 2,
                language: language_enum.en_us,
                value: content,
                format: formValues.contentFormat,
              },
            ],
          },
          button_text_translation: {
            __typename: "translation",
            id: 3,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 3,
                language: language_enum.en_us,
                value: formValues.buttonText,
                format: translation_value_format_enum.text,
              },
            ],
          },
          offer_coupon: {
            __typename: "offer_coupon",
            type: formValues.couponType as offer_coupon_type_enum,
            amount_off: formValues.amountOff?.toString() || "0",
            duration: formValues.duration as coupon_duration_enum,
            duration_interval: formValues.durationInterval
              ? (formValues.durationInterval as coupon_duration_interval_enum)
              : null,
            duration_count: formValues.durationCount,
            months: formValues.months,
          },
          offer_change_plan: null,
          offer_trial_extension: null,
          offer_pause_subscription: null,
          offer_custom: null,
          offer_timer: {
            __typename: "offer_timer",
            offer_timer_mode: formValues.timerMode as offer_timer_mode_enum,
            start_time: formValues.startTime
              ? formValues.startTime.toString()
              : null,
            end_time: formValues.endTime ? formValues.endTime.toString() : null,
            duration: formValues.offerLength,
            show_timer: formValues.countdownTimer,
          },
          goal: goal,
          style:
            (formValues.style as offer_style_enum) || offer_style_enum.modal,
          offer_autopilot: {
            __typename: "offer_autopilot",
            offer_id: 1,
          },
        });
        break;

      case "change_plan":
        setOffer({
          __typename: "offer",
          id: 1,
          token: "offer",
          type: offer_type_enum.change_plan,
          name: formValues.name,
          show_banner: formValues.showBanner,
          image_url: formValues.image,
          created_at: now,
          updated_at: now,
          headline_translation: {
            __typename: "translation",
            id: 1,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 1,
                language: language_enum.en_us,
                value: formValues.headline,
                format: translation_value_format_enum.text,
              },
            ],
          },
          content_translation: {
            __typename: "translation",
            id: 2,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 2,
                language: language_enum.en_us,
                value: content,
                format: formValues.contentFormat,
              },
            ],
          },
          button_text_translation: {
            __typename: "translation",
            id: 3,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 3,
                language: language_enum.en_us,
                value: formValues.buttonText,
                format: translation_value_format_enum.text,
              },
            ],
          },
          offer_coupon: null,
          offer_change_plan: {
            __typename: "offer_change_plan",
            platform_plan_id: formValues.planId,
            prorate: formValues.prorate,
            change_at: formValues.changeAt as offer_change_plan_change_at_enum,
          },
          offer_trial_extension: null,
          offer_pause_subscription: null,
          offer_custom: null,
          offer_timer: {
            __typename: "offer_timer",
            offer_timer_mode: formValues.timerMode as offer_timer_mode_enum,
            start_time: formValues.startTime
              ? formValues.startTime
                  .toZonedDateTime("UTC")
                  .toInstant()
                  .toString()
              : null,
            end_time: formValues.endTime
              ? formValues.endTime.toZonedDateTime("UTC").toInstant().toString()
              : null,
            duration: formValues.offerLength,
            show_timer: formValues.countdownTimer,
          },
          goal: goal,
          style:
            (formValues.style as offer_style_enum) || offer_style_enum.modal,
        });
        break;

      case "modify_subscription":
        setOffer({
          __typename: "offer",
          id: 1,
          token: "offer",
          type: offer_type_enum.modify_subscription,
          name: formValues.name,
          show_banner: formValues.showBanner,
          image_url: formValues.image,
          created_at: now,
          updated_at: now,
          headline_translation: {
            __typename: "translation",
            id: 1,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 1,
                language: language_enum.en_us,
                value: formValues.headline,
                format: translation_value_format_enum.text,
              },
            ],
          },
          content_translation: {
            __typename: "translation",
            id: 2,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 2,
                language: language_enum.en_us,
                value: content,
                format: formValues.contentFormat,
              },
            ],
          },
          button_text_translation: {
            __typename: "translation",
            id: 3,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 3,
                language: language_enum.en_us,
                value: formValues.buttonText,
                format: translation_value_format_enum.text,
              },
            ],
          },
          offer_coupon: null,
          offer_modify_subscription: {
            __typename: "offer_modify_subscription",
            prorate: formValues.prorate,
            modify_at:
              formValues.modifyAt as offer_modify_subscription_modify_at_enum,
            offer_modify_subscription_options: [],
          },
          offer_trial_extension: null,
          offer_pause_subscription: null,
          offer_custom: null,
          goal: goal,
          style:
            (formValues.style as offer_style_enum) || offer_style_enum.modal,
        });
        break;

      case "pause_subscription":
        const createOption = (index: 1 | 2 | 3) => {
          const option: FlowOfferPauseSubscriptionOptionFragment = {
            __typename: "offer_pause_subscription_option" as const,
            type:
              formValues[`option${index}Interval`] === "date"
                ? offer_pause_subscription_option_type_enum.date
                : offer_pause_subscription_option_type_enum.interval,
            interval: formValues[
              `option${index}Interval`
            ] as offer_pause_subscription_interval_enum,
            interval_count: formValues[`option${index}IntervalCount`],
          };
          if (formValues[`option${index}Text`]) {
            option.text_translation = {
              __typename: "translation" as const,
              id: index + 5,
              translation_values: [
                {
                  __typename: "translation_value" as const,
                  translation_id: index + 5,
                  language: language_enum.en_us,
                  value: JSON.parse(formValues[`option${index}Text`]!),
                  format: translation_value_format_enum.lexical,
                },
              ],
            };
          }

          return option;
        };

        const options: FlowOfferPauseSubscriptionOptionFragment[] = [];
        if (formValues.option1Present) {
          options.push(createOption(1));
        }
        if (formValues.option2Present) {
          options.push(createOption(2));
        }
        if (formValues.option3Present) {
          options.push(createOption(3));
        }

        setOffer({
          __typename: "offer",
          id: 1,
          token: "offer",
          type: offer_type_enum.pause_subscription,
          name: formValues.name,
          show_banner: formValues.showBanner,
          image_url: formValues.image,
          created_at: now,
          updated_at: now,
          headline_translation: {
            __typename: "translation",
            id: 1,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 1,
                language: language_enum.en_us,
                value: formValues.headline,
                format: translation_value_format_enum.text,
              },
            ],
          },
          content_translation: {
            __typename: "translation",
            id: 2,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 2,
                language: language_enum.en_us,
                value: content,
                format: formValues.contentFormat,
              },
            ],
          },
          button_text_translation: {
            __typename: "translation",
            id: 3,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 3,
                language: language_enum.en_us,
                value: formValues.buttonText,
                format: translation_value_format_enum.text,
              },
            ],
          },
          offer_coupon: null,
          offer_change_plan: null,
          offer_trial_extension: null,
          offer_pause_subscription: {
            __typename: "offer_pause_subscription",
            offer_pause_subscription_options: options,
          },
          offer_custom: null,
          goal: goal,
          style:
            (formValues.style as offer_style_enum) || offer_style_enum.modal,
        });
        break;

      case "trial_extension":
        setOffer({
          __typename: "offer",
          id: 1,
          token: "offer",
          type: offer_type_enum.trial_extension,
          name: formValues.name,
          show_banner: formValues.showBanner,
          image_url: formValues.image,
          created_at: now,
          updated_at: now,
          headline_translation: {
            __typename: "translation",
            id: 1,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 1,
                language: language_enum.en_us,
                value: formValues.headline,
                format: translation_value_format_enum.text,
              },
            ],
          },
          content_translation: {
            __typename: "translation",
            id: 2,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 2,
                language: language_enum.en_us,
                value: content,
                format: formValues.contentFormat,
              },
            ],
          },
          button_text_translation: {
            __typename: "translation",
            id: 3,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 3,
                language: language_enum.en_us,
                value: formValues.buttonText,
                format: translation_value_format_enum.text,
              },
            ],
          },
          offer_coupon: null,
          offer_change_plan: null,
          offer_trial_extension: {
            __typename: "offer_trial_extension",
            days: Number(formValues.days),
          },
          offer_pause_subscription: null,
          offer_custom: null,
          offer_timer: {
            __typename: "offer_timer",
            offer_timer_mode: formValues.timerMode as offer_timer_mode_enum,
            start_time: formValues.startTime
              ? formValues.startTime
                  .toZonedDateTime("UTC")
                  .toInstant()
                  .toString()
              : null,
            end_time: formValues.endTime
              ? formValues.endTime.toZonedDateTime("UTC").toInstant().toString()
              : null,
            duration: formValues.offerLength,
            show_timer: formValues.countdownTimer,
          },
          goal: goal,
          style:
            (formValues.style as offer_style_enum) || offer_style_enum.modal,
        });
        break;

      case "upgrade":
        setOffer({
          __typename: "offer",
          id: 1,
          token: "offer",
          type: offer_type_enum.upgrade,
          name: formValues.name,
          show_banner: formValues.showBanner,
          image_url: formValues.image,
          created_at: now,
          updated_at: now,
          headline_translation: {
            __typename: "translation",
            id: 1,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 1,
                language: language_enum.en_us,
                value: formValues.headline,
                format: translation_value_format_enum.text,
              },
            ],
          },
          content_translation: {
            __typename: "translation",
            id: 2,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 2,
                language: language_enum.en_us,
                value: content,
                format: formValues.contentFormat,
              },
            ],
          },
          button_text_translation: {
            __typename: "translation",
            id: 3,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 3,
                language: language_enum.en_us,
                value: formValues.buttonText,
                format: translation_value_format_enum.text,
              },
            ],
          },
          offer_upgrade: {
            __typename: "offer_upgrade",
            platform_plan_id: formValues.planId,
            change_at: formValues.changeAt as offer_change_plan_change_at_enum,
          },
          offer_trial_extension: null,
          offer_pause_subscription: null,
          offer_custom: null,
          goal: goal,
          style:
            (formValues.style as offer_style_enum) || offer_style_enum.modal,
        });
        break;

      case "downgrade":
        setOffer({
          __typename: "offer",
          id: 1,
          token: "offer",
          type: offer_type_enum.downgrade,
          name: formValues.name,
          show_banner: formValues.showBanner,
          image_url: formValues.image,
          created_at: now,
          updated_at: now,
          headline_translation: {
            __typename: "translation",
            id: 1,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 1,
                language: language_enum.en_us,
                value: formValues.headline,
                format: translation_value_format_enum.text,
              },
            ],
          },
          content_translation: {
            __typename: "translation",
            id: 2,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 2,
                language: language_enum.en_us,
                value: content,
                format: formValues.contentFormat,
              },
            ],
          },
          button_text_translation: {
            __typename: "translation",
            id: 3,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 3,
                language: language_enum.en_us,
                value: formValues.buttonText,
                format: translation_value_format_enum.text,
              },
            ],
          },
          offer_downgrade: {
            __typename: "offer_downgrade",
            platform_plan_id: formValues.planId,
            change_at: formValues.changeAt as offer_change_plan_change_at_enum,
          },
          offer_trial_extension: null,
          offer_pause_subscription: null,
          offer_custom: null,
          goal: goal,
          style:
            (formValues.style as offer_style_enum) || offer_style_enum.modal,
        });
        break;

      case "product_swap":
        setOffer({
          __typename: "offer",
          id: 1,
          token: "offer",
          type: offer_type_enum.product_swap,
          name: formValues.name,
          show_banner: formValues.showBanner,
          image_url: formValues.image,
          created_at: now,
          updated_at: now,
          headline_translation: {
            __typename: "translation",
            id: 1,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 1,
                language: language_enum.en_us,
                value: formValues.headline,
                format: translation_value_format_enum.text,
              },
            ],
          },
          content_translation: {
            __typename: "translation",
            id: 2,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 2,
                language: language_enum.en_us,
                value: content,
                format: formValues.contentFormat,
              },
            ],
          },
          button_text_translation: {
            __typename: "translation",
            id: 3,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 3,
                language: language_enum.en_us,
                value: formValues.buttonText,
                format: translation_value_format_enum.text,
              },
            ],
          },
          offer_coupon: null,
          offer_change_plan: null,
          offer_trial_extension: null,
          offer_pause_subscription: null,
          offer_product_swap: {
            __typename: "offer_product_swap",
            specific_products: formValues.specificProducts,
            offer_product_swap_platform_ecommerce_products:
              formValues.products.map((formProduct) => ({
                __typename: "offer_product_swap_platform_ecommerce_product",
                platform_ecommerce_product_id: formProduct.productId || "",
                platform_ecommerce_product: {
                  __typename: "platform_ecommerce_product",
                  platform_id: formProduct.productId || "",
                  name: formProduct.name,
                  image_url: formProduct.imageUrl,
                  platform_plan_ecommerce_products: [
                    {
                      __typename: "platform_plan_ecommerce_product",
                      discount_amount: formProduct.discountAmount,
                      discount_type: formProduct.discountType,
                    },
                  ],
                },
                offer_product_swap_platform_ecommerce_product_platform_variants:
                  formProduct.variants
                    .filter((formVariant) => formVariant.value)
                    .map((formVariant) => ({
                      __typename:
                        "offer_product_swap_platform_ecommerce_product_platform_variant",
                      platform_variant_id: formVariant.variantId,
                      platform_variant: {
                        __typename: "platform_variant",
                        platform_id: formVariant.variantId,
                        name: formVariant.name,
                        price: formVariant.price?.toString() || null,
                        platform_variant_options: formVariant.options?.map(
                          (formOption) => ({
                            __typename: "platform_variant_option",
                            key: formOption.key,
                            value: formOption.value,
                          })
                        ),
                      },
                    })),
              })),
          },
          offer_custom: null,
          goal: goal,
          style:
            (formValues.style as offer_style_enum) || offer_style_enum.modal,
        });
        break;

      case "reschedule_order":
        setOffer({
          __typename: "offer",
          id: 1,
          token: "offer",
          type: offer_type_enum.reschedule_order,
          name: formValues.name,
          show_banner: formValues.showBanner,
          image_url: formValues.image,
          created_at: now,
          updated_at: now,
          headline_translation: {
            __typename: "translation",
            id: 1,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 1,
                language: language_enum.en_us,
                value: formValues.headline,
                format: translation_value_format_enum.text,
              },
            ],
          },
          content_translation: {
            __typename: "translation",
            id: 2,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 2,
                language: language_enum.en_us,
                value: content,
                format: formValues.contentFormat,
              },
            ],
          },
          button_text_translation: {
            __typename: "translation",
            id: 3,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 3,
                language: language_enum.en_us,
                value: formValues.buttonText,
                format: translation_value_format_enum.text,
              },
            ],
          },
          offer_coupon: null,
          offer_change_plan: null,
          offer_trial_extension: null,
          offer_pause_subscription: null,
          offer_custom: null,
          offer_reschedule_order: {
            __typename: "offer_reschedule_order",
            skip: formValues.skipEnabled,
            reschedule: formValues.rescheduleEnabled,
            skip_button_text_translation: {
              __typename: "translation",
              id: 4,
              translation_values: [
                {
                  __typename: "translation_value",
                  translation_id: 4,
                  language: language_enum.en_us,
                  value: formValues.skipButtonText,
                  format: translation_value_format_enum.text,
                },
              ],
            },
            reschedule_button_text_translation: {
              __typename: "translation",
              id: 5,
              translation_values: [
                {
                  __typename: "translation_value",
                  translation_id: 5,
                  language: language_enum.en_us,
                  value: formValues.rescheduleButtonText,
                  format: translation_value_format_enum.text,
                },
              ],
            },
          },
          goal: goal,
          style:
            (formValues.style as offer_style_enum) || offer_style_enum.modal,
        });
        break;

      case "custom":
        setOffer({
          __typename: "offer",
          id: 1,
          token: "offer",
          type: offer_type_enum.custom,
          name: formValues.name,
          show_banner: formValues.showBanner,
          image_url: formValues.image,
          created_at: now,
          updated_at: now,
          headline_translation: {
            __typename: "translation",
            id: 1,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 1,
                language: language_enum.en_us,
                value: formValues.headline,
                format: translation_value_format_enum.text,
              },
            ],
          },
          content_translation: {
            __typename: "translation",
            id: 2,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 2,
                language: language_enum.en_us,
                value: content,
                format: formValues.contentFormat,
              },
            ],
          },
          button_text_translation: {
            __typename: "translation",
            id: 3,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 3,
                language: language_enum.en_us,
                value: formValues.buttonText,
                format: translation_value_format_enum.text,
              },
            ],
          },
          offer_coupon: null,
          offer_change_plan: null,
          offer_trial_extension: null,
          offer_pause_subscription: null,
          offer_custom: {
            __typename: "offer_custom",
            link_href: "",
            link_text: "",
          },
          offer_timer: {
            __typename: "offer_timer",
            offer_timer_mode: formValues.timerMode as offer_timer_mode_enum,
            start_time: formValues.startTime
              ? formValues.startTime
                  .toZonedDateTime("UTC")
                  .toInstant()
                  .toString()
              : null,
            end_time: formValues.endTime
              ? formValues.endTime.toZonedDateTime("UTC").toInstant().toString()
              : null,
            duration: formValues.offerLength,
            show_timer: formValues.countdownTimer,
          },
          goal: goal,
          style:
            (formValues.style as offer_style_enum) || offer_style_enum.modal,
        });
        break;
    }
  }, [swappableProducts, formValues, goal]);

  if (!offer) {
    return null;
  }

  const nextOrderDate = Temporal.Now.plainDateISO().add({ days: 3 });

  return displayMode === "card" ? (
    <div tw="w-full">
      <Offer
        offer={offer}
        nextOrderDate={nextOrderDate}
        flowText={flowText}
        onAccept={() => {}}
        swappableProducts={swappableProducts}
        disableVideoAutoPlay={true}
      />
    </div>
  ) : offer.style === "step" ? (
    <div
      css={[
        tw`border border-divider p-8 ml-[1rem] mr-[2rem] bg-white overflow-y-auto max-h-[40rem]`,
        displayMode === "panel" && tw`min-w-[496px]`,
      ]}
    >
      <Offer
        offer={offer}
        nextOrderDate={nextOrderDate}
        pauseReasons={pauseReasons}
        flowText={flowText}
        onAccept={() => {}}
        swappableProducts={swappableProducts}
        disableVideoAutoPlay={true}
      />
    </div>
  ) : (
    <OfferModal
      offer={offer}
      nextOrderDate={nextOrderDate}
      pauseReasons={pauseReasons}
      flowText={flowText}
      isOpen={true}
      inline={true}
      previewMode={previewMode}
      onAccept={() => {}}
      onDecline={() => {}}
      isFinalStep={true}
      onImageLoad={onImageLoad}
      showCloseX={goal === offer_goal_enum.conversion}
      swappableProducts={swappableProducts}
      disableVideoAutoPlay={true}
      showDeclineButton={showDeclineButton}
    />
  );
};

const BANNER_SCALE = 0.5;
const MODAL_SCALE = 0.7;
const CONVERSION_MODAL_SCALE = 0.5;

export default forwardRef<HTMLDivElement, OfferPreviewProps>((props, ref) => (
  <Container ref={ref} showTimer={!!props.showTimer}>
    {props.goal === "conversion" ? (
      <div
        tw="rounded border border-divider w-[570px] h-[500px] overflow-hidden"
        css={css`
          background-image: url(${placeholder});
          background-size: 570px;
        `}
      >
        <div
          css={
            props.formValues?.style === "banner"
              ? css`
                  transform-origin: top center;
                  transform: scale(${BANNER_SCALE});
                  width: calc(100% * 1 / ${BANNER_SCALE});
                  margin-left: calc(100% * ${BANNER_SCALE} * -1);
                `
              : css`
                  position: relative;
                  top: 145px;
                  transform-origin: top center;
                  transform: scale(
                    ${props.goal === "conversion"
                      ? CONVERSION_MODAL_SCALE
                      : MODAL_SCALE}
                  );
                `
          }
        >
          <OfferPreview {...props} />
        </div>
      </div>
    ) : (
      <OfferPreview {...props} />
    )}
  </Container>
));
