import { Temporal } from "@js-temporal/polyfill";
import classNames from "classnames";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { css } from "twin.macro";

import {
  FlowOfferFragment,
  FlowSubscriptionFragment,
  offer_goal_enum,
  offer_style_enum,
  offer_type_enum,
  PauseReason,
} from "../../../__generated__/graphql";
import Editor from "../../../common/editor2/Editor";
import { isContentEmpty } from "../../../common/editor2/lib";
import { useTranslations } from "../../../common/translations/TranslationsProvider";
import getPauseSubscriptionDescription from "./lib/getPauseSubscriptionDescription";
import renderHighlight from "./lib/renderHighlight";
import renderPlaceholder from "./lib/renderPlaceholder";
import { FlowError, FlowText } from "./lib/types";
import OfferButtons from "./OfferButtons";
import OfferContent from "./OfferContent";
import PauseDatesForm from "./PauseDatesForm";
import PauseReasonForm from "./PauseReasonForm";
import ProductSwapForm from "./ProductSwapForm";
import RescheduleOrderForm, {
  RescheduleOrderFormOption,
} from "./RescheduleOrderForm";
import CloseXButton from "./ui/CloseXButton";
import CountdownTimer from "./ui/CountdownTimer";
import FlowErrorAlert from "./ui/FlowErrorAlert";
import FlowModal, { FlowModalProps } from "./ui/FlowModal";

type OfferModalProps = FlowModalProps & {
  offer: FlowOfferFragment | null;
  nextOrderDate?: Temporal.PlainDate;
  pauseReasons?: PauseReason[];
  flowText: FlowText;
  onAccept: (
    selectedOptionIndex: number,
    groupOffer: null,
    selectedVariantId: string | null | undefined,
    rescheduleTo: Temporal.PlainDate | null | undefined,
    pauseReasonCode: string | null | undefined,
    pauseAt: Temporal.PlainDate | null | undefined,
    resumeAt: Temporal.PlainDate | null | undefined
  ) => void;
  onDecline: () => void;
  isFinalStep: boolean;
  previewMode?: boolean;
  onImageLoad?: () => void;
  showDeclineButton?: boolean;
  showCloseX?: boolean;
  portalContainer?: Element;
  showOverlay?: boolean;
  onDismiss?: () => void;
  startTimerFrom?: Date | null | undefined;
  swappableProducts?: FlowSubscriptionFragment | null;
  disableVideoAutoPlay?: boolean;
  error?: FlowError;
};

const OfferModal: React.FunctionComponent<OfferModalProps> = ({
  offer,
  nextOrderDate,
  pauseReasons,
  flowText,
  onAccept,
  onDecline,
  previewMode = false,
  isFinalStep,
  onImageLoad,
  showDeclineButton = true,
  showCloseX = false,
  portalContainer = document.body,
  showOverlay,
  onDismiss = () => {},
  startTimerFrom = null,
  swappableProducts = null,
  disableVideoAutoPlay,
  error,
  ...props
}) => {
  const { language, defaultLanguage, translationValue } = useTranslations();
  const [offerButtonsKey, setOfferButtonsKey] = useState(nanoid());
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [selectedVariantId, setSelectedVariantId] = useState<string | null>(
    null
  );
  const [isDismissing, setIsDismissing] = useState(false);
  const [selectedRescheduleOption, setSelectedRescheduleOption] =
    useState<RescheduleOrderFormOption>("skip");

  const [rescheduleDate, setRescheduleDate] = useState(nextOrderDate);
  const [pauseReasonCode, setPauseReasonCode] = useState(
    pauseReasons?.length === 1 ? pauseReasons[0].code : undefined
  );

  const selectedPauseReason = pauseReasons?.find(
    (r) => r.code === pauseReasonCode
  );

  const [pauseDate, setPauseDate] = useState(
    selectedPauseReason?.minimumStartDate
      ? Temporal.PlainDate.from(selectedPauseReason.minimumStartDate)
      : Temporal.Now.plainDateISO()
  );
  const [resumeDate, setResumeDate] = useState<Temporal.PlainDate>();

  useEffect(() => {
    setSelectedOptionIndex(0);
  }, [
    offer?.offer_pause_subscription?.offer_pause_subscription_options.length,
  ]);

  useEffect(() => {
    if (!rescheduleDate) {
      setRescheduleDate(nextOrderDate);
    }
  }, [nextOrderDate, rescheduleDate]);

  useEffect(() => {
    if (props.isOpen) {
      setOfferButtonsKey(nanoid());
    }
  }, [props.isOpen]);

  const handleDismiss = () => {
    setIsDismissing(true);
    setTimeout(() => {
      onDismiss();
    }, 100);
  };

  const bodyClasses = classNames({
    "flow-modal__body": true,
    "flow-modal__body--banner": offer?.style === offer_style_enum.banner,
    "flow-modal__body--conversion": offer?.goal === offer_goal_enum.conversion,
  });

  const getDelayedDateFromString = (
    delayString: string,
    startedAt?: Date | null | undefined
  ) => {
    const delayParts = delayString.split(":");
    let date = startedAt ? startedAt.getTime() : Date.now();
    if (delayParts.length >= 1) {
      date += parseInt(delayParts[0]) * 60 * 60 * 1000;
    }
    if (delayParts.length >= 2) {
      date += parseInt(delayParts[1]) * 60 * 1000;
    }
    if (delayParts.length >= 3) {
      date += parseInt(delayParts[2]) * 1000;
    }

    return date;
  };

  const containerClassnames = classNames(
    previewMode && "flow-modal__container--preview",
    offer?.goal === offer_goal_enum.conversion &&
      "flow-modal__container--convert",
    offer?.type === offer_type_enum.product_swap &&
      "flow-modal__container--product-swap"
  );

  if (offer?.offer_reschedule_order) {
    if (selectedRescheduleOption === "skip") {
      offer = {
        ...offer,
        button_text_translation:
          offer.offer_reschedule_order.skip_button_text_translation!,
      };
    } else if (selectedRescheduleOption === "reschedule") {
      offer = {
        ...offer,
        button_text_translation:
          offer.offer_reschedule_order.reschedule_button_text_translation!,
      };
    }
  }

  const selectedPauseOption = offer?.offer_pause_subscription
    ?.offer_pause_subscription_options.length
    ? offer.offer_pause_subscription.offer_pause_subscription_options[
        selectedOptionIndex
      ]
    : undefined;

  return (
    <FlowModal
      {...props}
      style={offer?.style || offer_style_enum.modal}
      addContainerClass={containerClassnames}
      portalContainer={portalContainer}
      goal={offer?.goal}
      showOverlay={showOverlay}
    >
      {offer &&
        offer.show_banner &&
        offer?.style === offer_style_enum.modal && (
          <div className="flow-modal__ribbon">
            <FormattedMessage defaultMessage="Special Offer" id="+XCEzH" />
          </div>
        )}
      <div
        className={bodyClasses}
        data-vulcan="offer"
        data-vulcan-id={offer?.token}
      >
        {showCloseX && offer?.style === "modal" && (
          <CloseXButton onClick={handleDismiss} data-vulcan="dismiss" />
        )}

        {!!error && (
          <FlowErrorAlert
            error={error}
            className={classNames({
              "flow-error--has-ribbon":
                !!offer?.show_banner && offer.style === offer_style_enum.modal,
            })}
          />
        )}

        <OfferContent
          offer={offer}
          previewMode={previewMode}
          onImageLoad={onImageLoad}
          language={language}
          defaultLanguage={defaultLanguage}
          baseClassName="flow-modal"
          disableVideoAutoPlay={disableVideoAutoPlay}
        />
        {offer?.type === "pause_subscription" && !!pauseReasons && (
          <div className="flow-modal__pause-reasons">
            <PauseReasonForm
              pauseReasons={pauseReasons}
              value={pauseReasonCode}
              hasMultipleOptions={
                offer?.offer_pause_subscription
                  ? offer.offer_pause_subscription
                      .offer_pause_subscription_options.length > 1
                  : false
              }
              onChange={(value) => setPauseReasonCode(value)}
            />
          </div>
        )}

        {(!pauseReasons || !!selectedPauseReason) &&
          offer?.offer_pause_subscription &&
          offer.offer_pause_subscription.offer_pause_subscription_options
            .length > 1 && (
            <div className="flow-modal__pause-options-container">
              {!!selectedPauseReason && <label>Pause duration</label>}
              <div className="flow-modal__pause-options">
                {offer.offer_pause_subscription.offer_pause_subscription_options.map(
                  (o, i) => {
                    const textTranslation = translationValue(
                      o.text_translation
                    ).value;

                    return (
                      <button
                        type="button"
                        key={`pause-option-${i}`}
                        className={classNames({
                          "flow-modal__pause-options__item": true,
                          active: i === selectedOptionIndex,
                        })}
                        onClick={() => setSelectedOptionIndex(i)}
                      >
                        {textTranslation && !isContentEmpty(textTranslation) ? (
                          <div
                            css={css`
                              && p {
                                text-align: center;
                                cursor: pointer;
                              }
                            `}
                          >
                            <Editor
                              initialValue={JSON.stringify(textTranslation)}
                              initialValueKey={JSON.stringify(textTranslation)}
                              isReadOnly={true}
                              isInline={true}
                            />
                          </div>
                        ) : o.type === "date" ? (
                          <>Choose date</>
                        ) : (
                          <>
                            Pause{" "}
                            {getPauseSubscriptionDescription(
                              [
                                {
                                  type: o.type,
                                  interval: o.interval,
                                  intervalCount: o.interval_count,
                                },
                              ],
                              false,
                              language,
                              renderPlaceholder,
                              renderHighlight
                            )}
                          </>
                        )}
                      </button>
                    );
                  }
                )}
              </div>
            </div>
          )}

        {!!selectedPauseReason && selectedPauseOption?.type === "date" && (
          <PauseDatesForm
            startDate={pauseDate}
            endDate={resumeDate}
            onChangeStartDate={(value) => setPauseDate(value)}
            onChangeEndDate={(value) => setResumeDate(value)}
            minimumStartDate={
              selectedPauseReason.minimumStartDate
                ? Temporal.PlainDate.from(selectedPauseReason.minimumStartDate)
                : Temporal.Now.plainDateISO()
            }
            maximumStartDate={
              selectedPauseReason.maximumStartDate
                ? Temporal.PlainDate.from(selectedPauseReason.maximumStartDate)
                : pauseDate.add({ days: 180 })
            }
            maximumEndDate={
              selectedPauseReason.maximumDays
                ? pauseDate.add({
                    days: selectedPauseReason.maximumDays,
                  })
                : pauseDate.add({ days: 180 })
            }
          />
        )}

        {offer?.type === offer_type_enum.reschedule_order &&
          offer.offer_reschedule_order?.reschedule && (
            <RescheduleOrderForm
              selectedOption={selectedRescheduleOption}
              nextOrderDate={nextOrderDate}
              rescheduleDate={rescheduleDate}
              onChangeSelectedOption={(option) =>
                setSelectedRescheduleOption(option)
              }
              onChangeRescheduleDate={(date) => setRescheduleDate(date)}
            />
          )}
      </div>
      {offer?.type === offer_type_enum.product_swap && (
        <div className="flow-modal__product-swap">
          <ProductSwapForm
            offer={offer}
            setSelectedVariantId={setSelectedVariantId}
            swappableProducts={swappableProducts}
          />
        </div>
      )}
      {offer &&
        offer.goal === offer_goal_enum.conversion &&
        offer.offer_timer?.show_timer &&
        (offer.offer_timer.end_time ? (
          <CountdownTimer
            banner={offer?.style === "banner"}
            targetDate={offer.offer_timer.end_time}
          />
        ) : (
          offer.offer_timer.duration && (
            <CountdownTimer
              banner={offer?.style === "banner"}
              targetDate={getDelayedDateFromString(
                offer.offer_timer.duration,
                startTimerFrom
              )}
            />
          )
        ))}

      <div
        className={classNames({
          "flow-modal__footer": true,
          "flow-modal__footer--convert":
            offer?.goal === offer_goal_enum.conversion,
          "flow-modal__footer--banner":
            offer?.style === offer_style_enum.banner,
        })}
      >
        <OfferButtons
          key={offerButtonsKey}
          offer={offer}
          flowText={flowText}
          previewMode={previewMode}
          language={language}
          defaultLanguage={defaultLanguage}
          isFinalStep={isFinalStep}
          showDeclineButton={showDeclineButton}
          showCloseX={showCloseX && offer?.style === "banner"}
          onAccept={() =>
            onAccept(
              selectedOptionIndex,
              null,
              selectedVariantId,
              selectedRescheduleOption === "reschedule"
                ? rescheduleDate
                : undefined,
              pauseReasonCode,
              selectedPauseOption?.type === "date" ? pauseDate : undefined,
              selectedPauseOption?.type === "date" ? resumeDate : undefined
            )
          }
          onDecline={onDecline}
          onDismiss={handleDismiss}
          isDismissing={isDismissing}
          isDisabled={
            (offer?.type === offer_type_enum.product_swap &&
              !selectedVariantId) ||
            (offer?.type === "reschedule_order" &&
              selectedRescheduleOption === "reschedule" &&
              rescheduleDate &&
              nextOrderDate &&
              rescheduleDate.equals(nextOrderDate)) ||
            (offer?.type === "pause_subscription" &&
              ((!!pauseReasons && !pauseReasonCode) ||
                (selectedPauseOption?.type === "date" &&
                  (!pauseDate || !resumeDate))))
          }
          error={error}
        />
      </div>
    </FlowModal>
  );
};

export default OfferModal;
