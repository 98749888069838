import { Temporal } from "@js-temporal/polyfill";

import {
  AppDeflectionFragment,
  FlowOfferFragment,
  FlowOfferRulesQuery,
  FlowQuery,
  FlowSubscriptionFragment,
  language_enum,
  PauseReason,
} from "../../__generated__/graphql";
import { AcknowledgementsManager } from "../../features/flow/edit/acknowledgementActions";
import { ConfirmationManager } from "../../features/flow/edit/confirmationManager";
import { FormManager } from "../../features/flow/edit/formManager";
import { InterventionManager } from "../../features/flow/edit/interventionManager";
import { QuestionManager } from "../../features/flow/edit/questionManager";
import { RuleManager } from "../../features/flow/edit/ruleActions";
import FlowVersionProvider from "../../features/public/flow/FlowVersionProvider";
import debugLog from "../../features/public/flow/lib/debugLog";
import mapFlowText from "../../features/public/flow/lib/mapFlowText";
import {
  CustomSubscriberDetails,
  FlowError,
  FlowStep,
  FlowText,
  QuestionAnswer,
} from "../../features/public/flow/lib/types";
import Acknowledgements from "../../features/public/flow/steps/Acknowledgements";
import Confirmation from "../../features/public/flow/steps/Confirmation";
import Deflection from "../../features/public/flow/steps/Deflection";
import DeflectionRules from "../../features/public/flow/steps/DeflectionRules";
import Form from "../../features/public/flow/steps/Form";
import Intervention from "../../features/public/flow/steps/Intervention";
import Offer from "../../features/public/flow/steps/Offer";
import OfferRules from "../../features/public/flow/steps/OfferRules";
import Question from "../../features/public/flow/steps/Question";
import SubscriberDetailsForm from "../../features/public/flow/steps/SubscriberDetailsForm";
import UpsellProvider from "../../features/upgrade-account/UpsellProvider";
import { FlowVersion } from "./types";

interface RenderStepOptions {
  isEditMode: boolean;
  flowToken: string;
  flow: FlowQuery["flow"][0] | undefined | null;
  flowVersion: FlowVersion;
  currentStep: FlowStep;
  onAcknowledgementsChanged: (acknowledged: boolean) => void;
  submitting: boolean;
  onQuestionValueUpdate: (value: QuestionAnswer) => void;
  acknowledgementsManager?: AcknowledgementsManager;
  formManager?: FormManager;
  questionManager?: QuestionManager;
  ruleManager?: RuleManager;
  confirmationManager?: ConfirmationManager;
  interventionManager?: InterventionManager;
  enabledLanguages: language_enum[];
  onDeflect: (deflectionId: number, flowActionId: number) => void;
  offerRules?: FlowOfferRulesQuery | null;
  onCustomSubscriberDetailsChanged: (
    details: CustomSubscriberDetails | undefined
  ) => void;
  onGoToNextStep: () => void;
  onCancel: () => void;
  offer: FlowOfferFragment | null;
  nextOrderDate?: Temporal.PlainDate;
  pauseReasons?: PauseReason[];
  onAcceptOffer: (selectedOptionIndex: number) => void;
  flowText: FlowText;
  isFreeMode: boolean;
  onClickUpgrade: () => void;
  intakeFormDisplayErrorMessage?: string;
  swappableProducts?: FlowSubscriptionFragment | null;
  deflections?: AppDeflectionFragment[];
  onEditDeflection?: () => void;
  onCreateDeflection?: () => void;
  acceptOfferError?: FlowError;
}

const renderStep = ({
  isEditMode,
  flowToken,
  flow,
  flowVersion,
  currentStep,
  onAcknowledgementsChanged,
  submitting,
  onQuestionValueUpdate,
  acknowledgementsManager,
  formManager,
  questionManager,
  ruleManager,
  confirmationManager,
  interventionManager,
  enabledLanguages,
  onDeflect,
  offerRules,
  onCustomSubscriberDetailsChanged,
  onGoToNextStep,
  onCancel,
  offer,
  nextOrderDate,
  pauseReasons,
  onAcceptOffer,
  flowText,
  isFreeMode,
  onClickUpgrade,
  intakeFormDisplayErrorMessage,
  swappableProducts = null,
  deflections = [],
  onEditDeflection = () => {},
  onCreateDeflection = () => {},
  acceptOfferError,
}: RenderStepOptions) => {
  debugLog("Rendering step", currentStep);

  if (!currentStep) {
    return null;
  }

  switch (currentStep.type) {
    case "subscriberDetailsForm":
      return (
        <SubscriberDetailsForm
          onChanged={onCustomSubscriberDetailsChanged}
          questions={flow?.flow_subscriber_form_questions || []}
          includeNameAndEmail={
            flow?.account.intake_form_name_email === false ? false : true
          }
          displayErrorMessage={intakeFormDisplayErrorMessage}
        />
      );

    case "intervention":
      if (!flow) {
        throw new Error("No flow");
      }

      return (
        <Intervention
          isEditMode={isEditMode}
          intervention={currentStep.intervention}
          interventionManager={interventionManager}
          ruleManager={ruleManager}
          flowId={flow.id}
          logoUrl={flow.logo_url}
          companyName={flow.account.title || ""}
          flowText={mapFlowText(flow?.flow_texts || [], flowVersion)}
          offer={offer}
          offerRules={offerRules}
          pauseReasons={pauseReasons}
          onClickContinue={onGoToNextStep}
          onClickCancel={onCancel}
          onAcceptOffer={onAcceptOffer}
        />
      );

    case "acknowledgementGroup":
      if (!flow) {
        throw new Error("No flow");
      }

      return (
        <Acknowledgements
          flowId={flow.id}
          flowToken={flowToken}
          isEditMode={isEditMode}
          acknowledgementGroup={currentStep.acknowledgementGroup}
          onChange={onAcknowledgementsChanged}
          manager={acknowledgementsManager}
        />
      );

    case "form":
      if (!flow) {
        throw new Error("No flow");
      }
      return (
        <Form
          form={currentStep.form}
          formStepQuestions={currentStep.questions}
          isEditMode={isEditMode}
          isSubmitting={submitting}
          onChange={onQuestionValueUpdate}
          formManager={formManager}
          questionManager={questionManager}
          enabledLanguages={enabledLanguages}
          isFreeMode={isFreeMode}
          flowText={flowText}
        />
      );

    case "question":
      if (!flow) {
        throw new Error("No flow");
      }

      return (
        <Question
          question={currentStep.question}
          conditionsReferencedBy={currentStep.conditionsReferencedBy}
          isEditMode={isEditMode}
          isSubmitting={submitting}
          onChange={onQuestionValueUpdate}
          manager={questionManager}
          enabledLanguages={enabledLanguages}
          optionIdsReferencedByOfferRules={
            currentStep.optionIdsReferencedByOfferRules
          }
          flowText={flowText}
        />
      );

    case "offerRuleGroup":
      if (!isEditMode) {
        if (offer?.style === "step") {
          return (
            <Offer
              offer={offer}
              flowText={flowText}
              swappableProducts={swappableProducts}
              nextOrderDate={nextOrderDate}
              pauseReasons={pauseReasons}
              onAccept={onAcceptOffer}
              error={acceptOfferError}
            />
          );
        }
        return null;
      }

      if (!ruleManager) {
        throw new Error("RuleManager is required");
      }

      if (!flow) {
        throw new Error("No flow");
      }

      if (!offerRules) {
        throw new Error("No offer rules");
      }

      return (
        <FlowVersionProvider version="draft">
          <UpsellProvider>
            <OfferRules
              key={currentStep.id}
              offerRuleGroupId={currentStep.offerRuleGroupId}
              offerRules={offerRules}
              flowId={flow.id}
              questions={currentStep.questions}
              manager={ruleManager}
              isFreeMode={isFreeMode}
              flowText={flowText}
              onClickUpgrade={onClickUpgrade}
            />
          </UpsellProvider>
        </FlowVersionProvider>
      );

    case "deflectionRuleGroup":
      if (!isEditMode) {
        return currentStep.deflection ? (
          <Deflection
            deflection={currentStep.deflection}
            disableVideoAutoPlay={false}
            previewMode={false}
            onDeflect={onDeflect}
          />
        ) : null;
      }

      if (!ruleManager) {
        throw new Error("RuleManager is required");
      }

      if (!flow) {
        throw new Error("No flow");
      }

      if (!offerRules) {
        throw new Error("No offer rules");
      }

      return (
        <FlowVersionProvider version="draft">
          <UpsellProvider>
            <DeflectionRules
              key={currentStep.id}
              ruleGroupId={currentStep.deflectionRuleGroupId}
              deflectionRules={offerRules}
              deflections={deflections}
              flowId={flow.id}
              questions={currentStep.questions}
              manager={ruleManager}
              flowText={flowText}
              isFreeMode={isFreeMode}
              onClickUpgrade={onClickUpgrade}
              onEditDeflection={onEditDeflection}
              onCreateDeflection={onCreateDeflection}
            />
          </UpsellProvider>
        </FlowVersionProvider>
      );

    case "confirmation":
      return (
        <Confirmation
          status={currentStep.status}
          confirmation={currentStep.confirmation}
          isEditMode={isEditMode}
          manager={confirmationManager}
        />
      );
  }
};

export default renderStep;
