import { faWeightHanging } from "@fortawesome/pro-regular-svg-icons";
import { faPlaneUp, faVial } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isPresent } from "ts-is-present";
import tw, { styled } from "twin.macro";

import { FlowOfferRulesQuery } from "../../../../__generated__/graphql";
import offerIcon from "../../../offers/lib/offerIcon";
import { useFlowVersion } from "../FlowVersionProvider";

interface ReadOnlyOfferRulesProps {
  offerRuleGroupId: number;
  offerRules: FlowOfferRulesQuery;
}

const Box = tw.div`font-semibold px-2 rounded-md text-white m-1 inline-block`;

export const AnyBox = styled(Box)`
  ${tw`bg-gray-800`}
`;

const SegmentBox = styled(Box)`
  ${tw`bg-purple-600`}
`;

const OfferBox = styled(Box)`
  ${tw`bg-orange-300`}
`;

const OfferTestBox = styled(Box)`
  ${tw`bg-orange-400`}
`;

const OfferAutopilotOfferBox = styled(Box)`
  ${tw`bg-orange-500`}
`;

const NoOfferBox = styled(Box)<{ isReallyOn: boolean }>`
  ${tw`bg-orange-300`}
  ${(props) => props.isReallyOn && tw`bg-red-500!`}
`;

const ReadOnlyOfferRules: React.FunctionComponent<ReadOnlyOfferRulesProps> = ({
  offerRuleGroupId,
  offerRules,
}) => {
  const { getObjectVersion } = useFlowVersion();

  const offerRuleGroup = offerRules.offer_rule_group.find(
    (group) => group.id === offerRuleGroupId
  );
  if (!offerRuleGroup) {
    throw new Error("Missing offer rule group");
  }

  const groupVersion = getObjectVersion(offerRuleGroup);

  const rules = groupVersion.offer_rule_group_version_offer_rules
    .map((rule) => rule.offer_rule)
    .filter(isPresent);

  return (
    <div tw="flex flex-col w-full mt-4">
      <div tw="flex w-full text-gray-600 font-semibold border-b border-divider pb-1">
        <div tw="w-1/2 px-1">Segment</div>
        <div tw="w-1/2 px-1">Offer</div>
      </div>

      {!rules.length && <div tw="pl-1 py-1 text-type-light">No rules</div>}

      {rules.map((rule) => {
        const ruleVersion = getObjectVersion(rule);
        const ruleRules = ruleVersion.offer_rule_version_offer_rule_rules
          .map((r) => r.offer_rule_rule)
          .filter(isPresent)
          .map((r) => ({
            ...r,
            weight: r.weight,
          }));

        return (
          <div key={rule.id} tw="flex w-full border-b py-2">
            <div tw="w-1/2">
              {!ruleVersion.offer_rule_version_segments.length && (
                <AnyBox>Any</AnyBox>
              )}
              {ruleVersion.offer_rule_version_segments.map(({ segment }) => {
                if (!segment) {
                  throw new Error("Missing segment");
                }
                return <SegmentBox key={segment.id}>{segment.name}</SegmentBox>;
              })}
            </div>
            <div tw="w-1/2">
              {ruleRules.map((r, ruleIndex) => (
                <div
                  key={`rule-${ruleIndex}`}
                  tw="relative mb-2 pb-1 border-b last:border-none last:pb-0 last:-mb-1"
                >
                  {ruleRules.length > 1 && (
                    <div tw="mb-1 ml-1 -mt-1 bg-gray-100 text-gray-800 rounded-md inline-block px-2">
                      <FontAwesomeIcon icon={faWeightHanging} /> Weight:{" "}
                      {r.weight}
                    </div>
                  )}

                  <div>
                    {(r.include_present_no_offer ||
                      (!r.offer_rule_rule_offers.length &&
                        !r.offer_rule_rule_offer_tests.length &&
                        !r.offer_rule_rule_offer_autopilot_offers.length)) && (
                      <NoOfferBox
                        isReallyOn={
                          !r.offer_rule_rule_offers.length &&
                          !r.offer_rule_rule_offer_tests.length &&
                          !r.offer_rule_rule_offer_autopilot_offers.length
                        }
                      >
                        Present no offer
                      </NoOfferBox>
                    )}
                    {r.offer_rule_rule_offers.map(({ offer }) => {
                      if (!offer) {
                        throw new Error("Missing offer");
                      }

                      const actualOffer = offerRules.offer.find(
                        (o) => o.id === offer.id
                      );
                      if (!actualOffer) {
                        throw new Error("Missing offer");
                      }

                      return (
                        <OfferBox key={offer.id}>
                          <FontAwesomeIcon
                            icon={offerIcon(actualOffer.type)}
                            transform="shrink-3"
                            tw="mr-1"
                          />{" "}
                          {offer.name}
                        </OfferBox>
                      );
                    })}
                    {r.offer_rule_rule_offer_tests.map(
                      ({ offer_test: offerTest }) => {
                        if (!offerTest) {
                          throw new Error("Missing offer test");
                        }

                        return (
                          <OfferTestBox key={offerTest.id}>
                            <FontAwesomeIcon
                              icon={faVial}
                              transform="shrink-3"
                            />{" "}
                            {offerTest.name}
                          </OfferTestBox>
                        );
                      }
                    )}
                    {r.offer_rule_rule_offer_autopilot_offers
                      .map(({ offer_autopilot }) => offer_autopilot?.offer)
                      .filter(isPresent)
                      .map((offer) => {
                        if (!offer) {
                          throw new Error("Missing offer");
                        }

                        const actualOffer = offerRules.offer_autopilot.find(
                          (o) => o.offer_id === offer.id
                        );
                        if (!actualOffer) {
                          throw new Error("Missing offer");
                        }

                        return (
                          <OfferAutopilotOfferBox key={offer.id}>
                            <FontAwesomeIcon
                              icon={faPlaneUp}
                              transform="shrink-3"
                              tw="mr-1"
                            />{" "}
                            {offer.name}
                          </OfferAutopilotOfferBox>
                        );
                      })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ReadOnlyOfferRules;
