import { Temporal } from "@js-temporal/polyfill";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { css } from "twin.macro";

import {
  FlowOfferFragment,
  FlowSubscriptionFragment,
  offer_type_enum,
  PauseReason,
} from "../../../../__generated__/graphql";
import Editor from "../../../../common/editor2/Editor";
import { isContentEmpty } from "../../../../common/editor2/lib";
import { useTranslations } from "../../../../common/translations/TranslationsProvider";
import getPauseSubscriptionDescription from "../../../public/flow/lib/getPauseSubscriptionDescription";
import renderHighlight from "../lib/renderHighlight";
import renderPlaceholder from "../lib/renderPlaceholder";
import { FlowError, FlowText } from "../lib/types";
import OfferButtons from "../OfferButtons";
import OfferContent from "../OfferContent";
import PauseDatesForm from "../PauseDatesForm";
import PauseReasonForm from "../PauseReasonForm";
import ProductSwapForm from "../ProductSwapForm";
import RescheduleOrderForm, {
  RescheduleOrderFormOption,
} from "../RescheduleOrderForm";
import FlowErrorAlert from "../ui/FlowErrorAlert";

interface OfferProps {
  offer: FlowOfferFragment;
  nextOrderDate?: Temporal.PlainDate;
  pauseReasons?: PauseReason[];
  flowText: FlowText;
  onAccept: (
    selectedOptionIndex: number,
    groupOffer: null,
    selectedVariantId: string | null | undefined,
    rescheduleTo: Temporal.PlainDate | null | undefined,
    pauseReasonCode: string | null | undefined,
    pauseAt: Temporal.PlainDate | null | undefined,
    resumeAt: Temporal.PlainDate | null | undefined
  ) => void;
  swappableProducts?: FlowSubscriptionFragment | null;
  disableVideoAutoPlay?: boolean;
  error?: FlowError;
}

const Offer: React.FunctionComponent<OfferProps> = ({
  offer,
  nextOrderDate,
  pauseReasons,
  flowText,
  onAccept,
  swappableProducts = null,
  disableVideoAutoPlay,
  error,
}) => {
  const { language, defaultLanguage, translationValue } = useTranslations();
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [selectedVariantId, setSelectedVariantId] = useState<string | null>(
    null
  );

  const [selectedRescheduleOption, setSelectedRescheduleOption] =
    useState<RescheduleOrderFormOption>("skip");

  const [rescheduleDate, setRescheduleDate] = useState(nextOrderDate);
  const [pauseReasonCode, setPauseReasonCode] = useState(
    pauseReasons?.length === 1 ? pauseReasons[0].code : undefined
  );

  const selectedPauseReason = pauseReasons?.find(
    (r) => r.code === pauseReasonCode
  );

  const [pauseDate, setPauseDate] = useState(
    selectedPauseReason?.minimumStartDate
      ? Temporal.PlainDate.from(selectedPauseReason.minimumStartDate)
      : Temporal.Now.plainDateISO()
  );
  const [resumeDate, setResumeDate] = useState<Temporal.PlainDate>();

  useEffect(() => {
    setSelectedOptionIndex(0);
  }, [
    offer?.offer_pause_subscription?.offer_pause_subscription_options.length,
  ]);

  if (offer?.offer_reschedule_order) {
    if (selectedRescheduleOption === "skip") {
      offer = {
        ...offer,
        button_text_translation:
          offer.offer_reschedule_order.skip_button_text_translation!,
      };
    } else if (selectedRescheduleOption === "reschedule") {
      offer = {
        ...offer,
        button_text_translation:
          offer.offer_reschedule_order.reschedule_button_text_translation!,
      };
    }
  }

  const selectedPauseOption = offer?.offer_pause_subscription
    ?.offer_pause_subscription_options.length
    ? offer.offer_pause_subscription.offer_pause_subscription_options[
        selectedOptionIndex
      ]
    : undefined;

  return (
    <div className="flow-step__offer" tw="px-8 pb-8">
      {!!error && <FlowErrorAlert error={error} />}

      <OfferContent
        offer={offer}
        previewMode={false}
        language={language}
        defaultLanguage={defaultLanguage}
        baseClassName="flow-step__offer"
        disableVideoAutoPlay={disableVideoAutoPlay}
      />

      {offer.offer_pause_subscription && (
        <div className="flow-step__offer__pause">
          {!!pauseReasons && (
            <div className="flow-step__offer__pause-reasons">
              <PauseReasonForm
                pauseReasons={pauseReasons}
                value={pauseReasonCode}
                hasMultipleOptions={
                  offer?.offer_pause_subscription
                    ? offer.offer_pause_subscription
                        .offer_pause_subscription_options.length > 1
                    : false
                }
                onChange={(value) => setPauseReasonCode(value)}
              />
            </div>
          )}
          {offer &&
            offer.offer_pause_subscription &&
            offer.offer_pause_subscription.offer_pause_subscription_options
              .length > 1 && (
              <div className="flow-step__offer__pause-options">
                {offer.offer_pause_subscription.offer_pause_subscription_options.map(
                  (o, i) => {
                    const textTranslation = translationValue(
                      o.text_translation
                    ).value;

                    return (
                      <button
                        type="button"
                        key={`pause-option-${i}`}
                        className={classNames({
                          "flow-step__offer__pause-options__item": true,
                          active: i === selectedOptionIndex,
                        })}
                        onClick={() => setSelectedOptionIndex(i)}
                      >
                        {textTranslation && !isContentEmpty(textTranslation) ? (
                          <div
                            css={css`
                              && p {
                                text-align: center;
                                cursor: pointer;
                              }
                            `}
                          >
                            <Editor
                              initialValue={JSON.stringify(textTranslation)}
                              initialValueKey={JSON.stringify(textTranslation)}
                              isReadOnly={true}
                              isInline={true}
                            />
                          </div>
                        ) : (
                          <>
                            Pause{" "}
                            {getPauseSubscriptionDescription(
                              [
                                {
                                  type: o.type,
                                  interval: o.interval,
                                  intervalCount: o.interval_count,
                                },
                              ],
                              false,
                              language,
                              renderPlaceholder,
                              renderHighlight
                            )}
                          </>
                        )}
                      </button>
                    );
                  }
                )}
              </div>
            )}

          {!!selectedPauseReason && selectedPauseOption?.type === "date" && (
            <PauseDatesForm
              startDate={pauseDate}
              endDate={resumeDate}
              onChangeStartDate={(value) => setPauseDate(value)}
              onChangeEndDate={(value) => setResumeDate(value)}
              minimumStartDate={
                selectedPauseReason.minimumStartDate
                  ? Temporal.PlainDate.from(
                      selectedPauseReason.minimumStartDate
                    )
                  : Temporal.Now.plainDateISO()
              }
              maximumStartDate={
                selectedPauseReason.maximumStartDate
                  ? Temporal.PlainDate.from(
                      selectedPauseReason.maximumStartDate
                    )
                  : pauseDate.add({ days: 180 })
              }
              maximumEndDate={
                selectedPauseReason.maximumDays
                  ? pauseDate.add({
                      days: selectedPauseReason.maximumDays,
                    })
                  : pauseDate.add({ days: 180 })
              }
            />
          )}
        </div>
      )}

      {offer?.type === offer_type_enum.reschedule_order &&
        offer.offer_reschedule_order?.reschedule && (
          <RescheduleOrderForm
            selectedOption={selectedRescheduleOption}
            nextOrderDate={nextOrderDate}
            rescheduleDate={rescheduleDate}
            onChangeSelectedOption={(option) =>
              setSelectedRescheduleOption(option)
            }
            onChangeRescheduleDate={(date) => setRescheduleDate(date)}
          />
        )}
      {offer?.type === offer_type_enum.product_swap && (
        <ProductSwapForm
          offer={offer}
          setSelectedVariantId={setSelectedVariantId}
          swappableProducts={swappableProducts}
        />
      )}
      <div tw="mt-4">
        <OfferButtons
          offer={offer}
          previewMode={false}
          language={language}
          defaultLanguage={defaultLanguage}
          flowText={flowText}
          onAccept={() =>
            onAccept(
              selectedOptionIndex,
              null,
              selectedVariantId,
              selectedRescheduleOption === "reschedule"
                ? rescheduleDate
                : undefined,
              pauseReasonCode,
              selectedPauseOption?.type === "date" ? pauseDate : undefined,
              selectedPauseOption?.type === "date" ? resumeDate : undefined
            )
          }
          onDecline={() => {}}
          isFinalStep={false}
          showDeclineButton={false}
          isDisabled={
            (offer?.type === offer_type_enum.product_swap &&
              !selectedVariantId) ||
            (offer?.type === "pause_subscription" &&
              ((!!pauseReasons && !pauseReasonCode) ||
                (selectedPauseOption?.type === "date" &&
                  (!pauseDate || !resumeDate))))
          }
          error={error}
        />
      </div>
    </div>
  );
};

export default Offer;
