import { faGift } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import tw from "twin.macro";

import {
  FlowOfferFragment,
  language_enum,
} from "../../../__generated__/graphql";
import translationText from "../../../common/translationText";
import offerIcon from "../../offers/lib/offerIcon";
import { usePropertyValues } from "../../properties/lib/propertyValues";
import renderOfferContent from "./lib/renderOfferContent";

interface OfferGroupContentProps {
  offer: FlowOfferFragment | null;
  showImages: boolean;
  previewMode: boolean;
  onImageLoad?: () => void;
  language: language_enum;
  defaultLanguage: language_enum;
  baseClassName: string;
}

const Placeholder = tw.span`text-type-light`;

const OfferGroupContent: React.FunctionComponent<OfferGroupContentProps> = ({
  offer,
  showImages,
  previewMode,
  onImageLoad,
  language,
  defaultLanguage,
  baseClassName,
}) => {
  const headline = translationText(
    offer?.headline_translation,
    language,
    defaultLanguage
  ) ? (
    translationText(offer?.headline_translation, language, defaultLanguage)
  ) : !offer?.image_url ? (
    <Placeholder>Headline</Placeholder>
  ) : null;

  const { propertyValues, propertyConfig, showPlaceholders } =
    usePropertyValues();

  const content = renderOfferContent({
    offer,
    previewMode,
    language,
    defaultLanguage,
    propertyValues,
    propertyConfig,
    showPropertyPlaceholders: showPlaceholders,
  });

  const image = offer?.type ? offerIcon(offer?.type) : faGift;

  return offer ? (
    <>
      {showImages && !!offer.image_url && (
        <img
          className={`${baseClassName}__group-image`}
          src={offer.image_url}
          alt=""
          onLoad={() => onImageLoad && onImageLoad()}
        />
      )}
      {showImages && !offer.image_url && (
        <div className={`${baseClassName}__group-no-image`}>
          <div className={`${baseClassName}__group-circle-wrapper`}>
            <div className={`${baseClassName}__group-circle`}>
              <FontAwesomeIcon
                icon={image}
                fixedWidth
                transform="down-6 right-4"
                size="2x"
              />
            </div>
          </div>
        </div>
      )}
      {offer.show_banner && (
        <div className="flow-modal__group-ribbon">
          <FormattedMessage defaultMessage="Special Offer" id="+XCEzH" />
        </div>
      )}
      <h3
        className={classNames({
          [`${baseClassName}__group-headline`]: true,
          [`${baseClassName}__group-headline--has-ribbon`]: offer.show_banner,
        })}
      >
        {headline}
      </h3>
      {content && (
        <div className={`${baseClassName}__group-content`}>{content}</div>
      )}
    </>
  ) : null;
};

export default OfferGroupContent;
