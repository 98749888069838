import { Temporal } from "@js-temporal/polyfill";
import classNames from "classnames";
import { nanoid } from "nanoid";
import { createRef, useEffect, useState } from "react";
import { css } from "twin.macro";

import {
  FlowOfferGroupFragment,
  PauseReason,
} from "../../../__generated__/graphql";
import renderContent from "../../../common/editor/lib/renderContent";
import Editor from "../../../common/editor2/Editor";
import { isContentEmpty } from "../../../common/editor2/lib";
import ScrollIndicator from "../../../common/ScrollIndicator";
import { useTranslations } from "../../../common/translations/TranslationsProvider";
import { usePropertyValues } from "../../properties/lib/propertyValues";
import getPauseSubscriptionDescription from "./lib/getPauseSubscriptionDescription";
import renderHighlight from "./lib/renderHighlight";
import renderPlaceholder from "./lib/renderPlaceholder";
import { FlowError, FlowText } from "./lib/types";
import OfferButtons from "./OfferButtons";
import OfferGroupContent from "./OfferGroupContent";
import PauseDatesForm from "./PauseDatesForm";
import PauseReasonForm from "./PauseReasonForm";
import CloseXButton from "./ui/CloseXButton";
import FlowErrorAlert from "./ui/FlowErrorAlert";
import FlowModal, { FlowModalProps } from "./ui/FlowModal";

type OfferGroupModalProps = FlowModalProps & {
  offerGroup: FlowOfferGroupFragment | null;
  flowText: FlowText;
  pauseReasons?: PauseReason[];
  onAccept: (
    selectedOptionIndex: number,
    offerId: string,
    pauseReasonCode: string | null | undefined,
    pauseAt: Temporal.PlainDate | null | undefined,
    resumeAt: Temporal.PlainDate | null | undefined
  ) => void;
  onDecline: () => void;
  isFinalStep: boolean;
  previewMode?: boolean;
  onImageLoad?: () => void;
  onClose?: () => void;
  showCloseX?: boolean;
  error?: FlowError;
};

const OfferGroupModal: React.FunctionComponent<OfferGroupModalProps> = ({
  offerGroup,
  flowText,
  pauseReasons,
  onAccept,
  onDecline,
  previewMode = false,
  isFinalStep,
  onImageLoad,
  onClose = () => {},
  showCloseX = false,
  error,
  ...props
}) => {
  const { language, defaultLanguage, translationValue } = useTranslations();
  const { propertyValues, propertyConfig, showPlaceholders } =
    usePropertyValues();
  const [offerButtonsKey, setOfferButtonsKey] = useState(nanoid());
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [isCanceling, setIsCanceling] = useState(false);

  const [pauseReasonCode, setPauseReasonCode] = useState(
    pauseReasons?.length === 1 ? pauseReasons[0].code : undefined
  );

  const selectedPauseReason = pauseReasons?.find(
    (r) => r.code === pauseReasonCode
  );

  const [pauseDate, setPauseDate] = useState(
    selectedPauseReason?.minimumStartDate
      ? Temporal.PlainDate.from(selectedPauseReason.minimumStartDate)
      : Temporal.Now.plainDateISO()
  );
  const [resumeDate, setResumeDate] = useState<Temporal.PlainDate>();

  const target = createRef() as React.RefObject<HTMLDivElement>;

  useEffect(() => {
    if (props.isOpen) {
      setIsCanceling(false);
      setOfferButtonsKey(nanoid());
    }
  }, [props.isOpen]);

  const offers = offerGroup?.offer_group_offers.filter((o) => !!o.offer);

  const headlineText = translationValue(offerGroup?.headline_translation).value;
  const contentText = offerGroup?.content_translation
    ? renderContent(
        translationValue(offerGroup?.content_translation).value,
        false,
        propertyValues,
        propertyConfig,
        showPlaceholders
      )
    : "";
  const cancelText = translationValue(
    offerGroup?.cancel_text_translation
  ).value;

  const pause = offerGroup?.offer_group_offers.find(
    (o) => !!o.offer?.offer_pause_subscription
  );

  const selectedPauseOption = pause?.offer?.offer_pause_subscription
    ?.offer_pause_subscription_options.length
    ? pause.offer.offer_pause_subscription.offer_pause_subscription_options[
        selectedOptionIndex
      ]
    : undefined;

  return (
    <FlowModal addContainerClass="flow-modal__container--group" {...props}>
      {!!error && (
        <div className="flow-modal__group__error">
          <FlowErrorAlert error={error} />
        </div>
      )}

      <div className="flow-modal__group-header">
        <h2>{headlineText}</h2>
        <h4>{contentText}</h4>
      </div>

      <div className="flow-modal__group" ref={target}>
        {offerGroup &&
          offers &&
          offers.map(
            (offer) =>
              !!offer.offer && (
                <div
                  className="flow-modal__group-body"
                  data-vulcan="offer"
                  data-vulcan-id={offerGroup?.token}
                  key={offer.offer.id}
                >
                  {showCloseX && <CloseXButton onClick={onClose} />}
                  <OfferGroupContent
                    offer={offer.offer}
                    showImages={offerGroup.show_images}
                    previewMode={previewMode}
                    onImageLoad={onImageLoad}
                    language={language}
                    defaultLanguage={defaultLanguage}
                    baseClassName="flow-modal"
                    key={offer.offer.token}
                  />
                  {offer.offer.type === "pause_subscription" &&
                    !!pauseReasons && (
                      <div className="flow-modal__pause-reasons">
                        <PauseReasonForm
                          pauseReasons={pauseReasons}
                          value={pauseReasonCode}
                          hasMultipleOptions={
                            offer.offer.offer_pause_subscription
                              ? offer.offer.offer_pause_subscription
                                  .offer_pause_subscription_options.length > 1
                              : false
                          }
                          onChange={(value) => setPauseReasonCode(value)}
                        />
                      </div>
                    )}

                  {(!pauseReasons || !!selectedPauseReason) &&
                    offer.offer.offer_pause_subscription &&
                    offer.offer.offer_pause_subscription
                      .offer_pause_subscription_options.length > 1 && (
                      <div className="flow-modal__pause-options">
                        {offer.offer.offer_pause_subscription.offer_pause_subscription_options.map(
                          (o, i) => {
                            const textTranslation = translationValue(
                              o.text_translation
                            ).value;

                            return (
                              <button
                                type="button"
                                key={`pause-option-${i}`}
                                className={classNames({
                                  "flow-modal__pause-options__item": true,
                                  active: i === selectedOptionIndex,
                                })}
                                onClick={() => setSelectedOptionIndex(i)}
                              >
                                {textTranslation &&
                                !isContentEmpty(textTranslation) ? (
                                  <div
                                    css={css`
                                      && p {
                                        text-align: center;
                                        cursor: pointer;
                                      }
                                    `}
                                  >
                                    <Editor
                                      initialValue={JSON.stringify(
                                        textTranslation
                                      )}
                                      initialValueKey={JSON.stringify(
                                        textTranslation
                                      )}
                                      isReadOnly={true}
                                      isInline={true}
                                    />
                                  </div>
                                ) : (
                                  <>
                                    Pause{" "}
                                    {getPauseSubscriptionDescription(
                                      [
                                        {
                                          type: o.type,
                                          interval: o.interval,
                                          intervalCount: o.interval_count,
                                        },
                                      ],
                                      false,
                                      language,
                                      renderPlaceholder,
                                      renderHighlight
                                    )}
                                  </>
                                )}
                              </button>
                            );
                          }
                        )}
                      </div>
                    )}

                  {offer.offer.type === "pause_subscription" &&
                    !!selectedPauseReason &&
                    selectedPauseOption?.type === "date" && (
                      <PauseDatesForm
                        startDate={pauseDate}
                        endDate={resumeDate}
                        onChangeStartDate={(value) => setPauseDate(value)}
                        onChangeEndDate={(value) => setResumeDate(value)}
                        minimumStartDate={
                          selectedPauseReason.minimumStartDate
                            ? Temporal.PlainDate.from(
                                selectedPauseReason.minimumStartDate
                              )
                            : Temporal.Now.plainDateISO()
                        }
                        maximumStartDate={
                          selectedPauseReason.maximumStartDate
                            ? Temporal.PlainDate.from(
                                selectedPauseReason.maximumStartDate
                              )
                            : pauseDate.add({ days: 180 })
                        }
                        maximumEndDate={
                          selectedPauseReason.maximumDays
                            ? pauseDate.add({
                                days: selectedPauseReason.maximumDays,
                              })
                            : pauseDate.add({ days: 180 })
                        }
                      />
                    )}

                  <div className="flow-modal__group-button">
                    <OfferButtons
                      key={offerButtonsKey}
                      offer={offer.offer}
                      flowText={flowText}
                      previewMode={previewMode}
                      language={language}
                      defaultLanguage={defaultLanguage}
                      isFinalStep={isFinalStep}
                      onAccept={() =>
                        onAccept(
                          selectedOptionIndex,
                          offer.offer?.token || "",
                          pauseReasonCode,
                          selectedPauseOption?.type === "date"
                            ? pauseDate
                            : undefined,
                          selectedPauseOption?.type === "date"
                            ? resumeDate
                            : undefined
                        )
                      }
                      onDecline={() => {}}
                      showDeclineButton={false}
                      error={error}
                      isDisabled={
                        isCanceling ||
                        (offer.offer.type === "pause_subscription" &&
                          ((!!pauseReasons && !pauseReasonCode) ||
                            (selectedPauseOption?.type === "date" &&
                              (!pauseDate || !resumeDate))))
                      }
                    />
                  </div>
                </div>
              )
          )}
      </div>
      <div className="flow-modal__group-scroll-indicators">
        <ScrollIndicator target={target} count={offers?.length || 0} />
      </div>
      <div className="flow-modal__group-footer">
        <button
          disabled={isCanceling}
          onClick={() => {
            if (previewMode) {
              return;
            }
            setIsCanceling(true);

            setTimeout(() => {
              onDecline();
            }, 100);
          }}
        >
          {cancelText}
        </button>
      </div>
    </FlowModal>
  );
};

export default OfferGroupModal;
