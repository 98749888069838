import { faGift } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Temporal } from "@js-temporal/polyfill";
import { ELEMENT_PARAGRAPH } from "@udecode/slate-plugins";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Node } from "slate";
import tw, { css, theme } from "twin.macro";

import {
  coupon_duration_enum,
  FlowInterventionFragment,
  FlowOfferFragment,
  FlowOfferRulesQuery,
  language_enum,
  offer_coupon_type_enum,
  offer_goal_enum,
  offer_style_enum,
  offer_type_enum,
  PauseReason,
  translation_value_format_enum,
} from "../../../../__generated__/graphql";
import Editor from "../../../../common/editor2/Editor";
import { isContentEmpty } from "../../../../common/editor2/lib";
import StandardLinkButton from "../../../../common/StandardLinkButton";
import { useTranslations } from "../../../../common/translations/TranslationsProvider";
import EditableFlowText from "../../../flow/edit/EditableFlowText";
import { InterventionManager } from "../../../flow/edit/interventionManager";
import { RuleManager } from "../../../flow/edit/ruleActions";
import getPauseSubscriptionDescription from "../../../public/flow/lib/getPauseSubscriptionDescription";
import { useFlowVersion } from "../FlowVersionProvider";
import renderHighlight from "../lib/renderHighlight";
import renderPlaceholder from "../lib/renderPlaceholder";
import { FlowText } from "../lib/types";
import OfferButtons from "../OfferButtons";
import OfferContent from "../OfferContent";
import PauseDatesForm from "../PauseDatesForm";
import PauseReasonForm from "../PauseReasonForm";
import FlowButton from "../ui/FlowButton";
import OfferRulesModal from "./OfferRulesModal";
import ReadOnlyOfferRules from "./ReadOnlyOfferRules";

interface InterventionProps {
  isEditMode: boolean;
  intervention: FlowInterventionFragment;
  flowId: number;
  interventionManager?: InterventionManager;
  ruleManager?: RuleManager;
  logoUrl: string | null | undefined;
  companyName: string;
  flowText: FlowText;
  offerRules?: FlowOfferRulesQuery | null;
  offer: FlowOfferFragment | null;
  pauseReasons?: PauseReason[];
  onClickContinue: () => void;
  onClickCancel: () => void;
  onAcceptOffer: (
    selectedOptionIndex: number,
    pauseReasonCode: string | null | undefined,
    pauseAt: Temporal.PlainDate | null | undefined,
    resumeAt: Temporal.PlainDate | null | undefined
  ) => void;
}

const previewOffer: FlowOfferFragment = {
  __typename: "offer",
  id: 1,
  token: "offer",
  type: offer_type_enum.coupon,
  name: "Preview",
  show_banner: true,
  goal: offer_goal_enum.retention,
  style: offer_style_enum.modal,
  image_url: null,
  created_at: Temporal.Now.instant().toString(),
  updated_at: Temporal.Now.instant().toString(),
  headline_translation: {
    __typename: "translation",
    id: 1,
    translation_values: [
      {
        __typename: "translation_value",
        translation_id: 1,
        language: language_enum.en_us,
        value: "Save customers with an offer",
        format: translation_value_format_enum.text,
      },
    ],
  },
  content_translation: {
    __typename: "translation",
    id: 2,
    translation_values: [
      {
        __typename: "translation_value",
        translation_id: 2,
        language: language_enum.en_us,
        value: [
          {
            type: ELEMENT_PARAGRAPH,
            children: [
              {
                text: "Incentivize your customers to stay with an offer.",
              },
            ],
          },
        ],
        format: translation_value_format_enum.slate,
      },
    ],
  },
  button_text_translation: {
    __typename: "translation",
    id: 3,
    translation_values: [
      {
        __typename: "translation_value",
        translation_id: 3,
        language: language_enum.en_us,
        value: "Accept offer",
        format: translation_value_format_enum.text,
      },
    ],
  },
  offer_coupon: {
    __typename: "offer_coupon",
    type: offer_coupon_type_enum.percentage,
    amount_off: "40",
    duration: coupon_duration_enum.forever,
  },
  offer_change_plan: null,
  offer_trial_extension: null,
  offer_pause_subscription: null,
  offer_custom: null,
};

const Intervention: React.FunctionComponent<InterventionProps> = ({
  isEditMode,
  intervention,
  flowId,
  interventionManager,
  ruleManager,
  logoUrl,
  companyName,
  flowText,
  offerRules,
  offer,
  pauseReasons,
  onClickContinue,
  onClickCancel,
  onAcceptOffer,
}) => {
  const { getObjectVersion } = useFlowVersion();
  const { language, defaultLanguage, translationValue } = useTranslations();
  const [offerRulesModalIsOpen, setOfferRulesModalIsOpen] = useState(false);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);

  const interventionVersion = getObjectVersion(intervention);
  const [pauseReasonCode, setPauseReasonCode] = useState(
    pauseReasons?.length === 1 ? pauseReasons[0].code : undefined
  );

  const selectedPauseReason = pauseReasons?.find(
    (r) => r.code === pauseReasonCode
  );

  const [pauseDate, setPauseDate] = useState(
    selectedPauseReason?.minimumStartDate
      ? Temporal.PlainDate.from(selectedPauseReason.minimumStartDate)
      : Temporal.Now.plainDateISO()
  );
  const [resumeDate, setResumeDate] = useState<Temporal.PlainDate>();

  useEffect(() => {
    setSelectedOptionIndex(0);
  }, [
    offer?.offer_pause_subscription?.offer_pause_subscription_options.length,
  ]);

  const handleUpdateContentTranslation = (
    value: Partial<Record<language_enum, Node[]>>
  ) => {
    if (!interventionManager) {
      return;
    }

    interventionManager.updateInterventionTranslations(intervention.id, {
      contentTranslation: value,
    });
  };

  const handleUpdateContinueButtonTranslation = (
    value: Partial<Record<language_enum, Node[]>>
  ) => {
    if (!interventionManager) {
      return;
    }

    interventionManager.updateInterventionTranslations(intervention.id, {
      continueButtonTranslation: value,
    });
  };

  const handleUpdateCancelButtonTranslation = (
    value: Partial<Record<language_enum, Node[]>>
  ) => {
    if (!interventionManager) {
      return;
    }

    interventionManager.updateInterventionTranslations(intervention.id, {
      cancelButtonTranslation: value,
    });
  };

  const showOffer = isEditMode ? previewOffer : offer;

  const selectedPauseOption = offer?.offer_pause_subscription
    ?.offer_pause_subscription_options.length
    ? offer.offer_pause_subscription.offer_pause_subscription_options[
        selectedOptionIndex
      ]
    : undefined;

  return (
    <div>
      <div className="flow-instant-cancel__heading" tw="flex">
        <div
          className="flow-instant-cancel__brand"
          css={isEditMode || !!offer ? tw`w-1/2` : tw`w-2/3 mx-auto`}
        >
          {logoUrl ? (
            <img
              src={logoUrl}
              alt={companyName}
              className="flow-sidebar__logo"
            />
          ) : (
            <h1 className="flow-sidebar__company-name" tw="mx-auto">
              {companyName}
            </h1>
          )}
        </div>
      </div>

      <div className={classNames("flow-instant-cancel")} tw="flex w-full">
        <div
          className={classNames("flow-instant-cancel__section", {
            "intervention-text-content": isEditMode,
          })}
          css={[
            isEditMode || !!offer ? tw`w-1/2` : tw`w-2/3 mx-auto`,
            tw`pr-8`,
          ]}
        >
          <div className="flow-step__content">
            <EditableFlowText
              translation={interventionVersion.content_translation}
              isEditable={isEditMode}
              isInline={false}
              onSave={handleUpdateContentTranslation}
              placeholder="Enter content"
              tallEditor={true}
              imagesEnabled={true}
              videosEnabled={false}
              hoverContentClass="intervention-text-content"
            />
          </div>
          <FlowButton
            buttonType="secondary"
            onClick={onClickContinue}
            isEditMode={isEditMode}
          >
            <EditableFlowText
              isEditable={isEditMode}
              translation={interventionVersion.continue_button_translation}
              onSave={handleUpdateContinueButtonTranslation}
              hoverContentClass="intervention-text-content"
            />
          </FlowButton>
          <div tw="mt-4">
            <button className="flow-link-button" onClick={onClickCancel}>
              <EditableFlowText
                isEditable={isEditMode}
                translation={interventionVersion.cancel_button_translation}
                onSave={handleUpdateCancelButtonTranslation}
                hoverContentClass="intervention-text-content"
              />
            </button>
          </div>
        </div>

        {(isEditMode || !!offer) && (
          <div className="flow-instant-cancel__section" tw="w-1/2">
            {!isEditMode && showOffer && (
              <div className="flow-instant-cancel__offer" tw="px-8 pb-8">
                {showOffer.show_banner && (
                  <div className="flow-instant-cancel__offer__ribbon" tw="mb-4">
                    <FormattedMessage
                      defaultMessage="Special Offer"
                      id="+XCEzH"
                    />
                  </div>
                )}
                <OfferContent
                  offer={showOffer}
                  previewMode={isEditMode}
                  language={language}
                  defaultLanguage={defaultLanguage}
                  baseClassName="flow-instant-cancel__offer"
                />

                {offer?.offer_pause_subscription && (
                  <div className="flow-instant-cancel__offer__pause">
                    {!!pauseReasons && (
                      <div className="flow-step__offer__pause-reasons">
                        <PauseReasonForm
                          pauseReasons={pauseReasons}
                          value={pauseReasonCode}
                          hasMultipleOptions={
                            offer?.offer_pause_subscription
                              ? offer.offer_pause_subscription
                                  .offer_pause_subscription_options.length > 1
                              : false
                          }
                          onChange={(value) => setPauseReasonCode(value)}
                        />
                      </div>
                    )}
                    {offer &&
                      offer.offer_pause_subscription &&
                      offer.offer_pause_subscription
                        .offer_pause_subscription_options.length > 1 && (
                        <div className="flow-instant-cancel__pause-options">
                          {offer.offer_pause_subscription.offer_pause_subscription_options.map(
                            (o, i) => {
                              const textTranslation = translationValue(
                                o.text_translation
                              ).value;

                              return (
                                <button
                                  type="button"
                                  key={`pause-option-${i}`}
                                  className={classNames({
                                    "flow-instant-cancel__pause-options__item":
                                      true,
                                    active: i === selectedOptionIndex,
                                  })}
                                  onClick={() => setSelectedOptionIndex(i)}
                                >
                                  {textTranslation &&
                                  !isContentEmpty(textTranslation) ? (
                                    <div
                                      css={css`
                                        && p {
                                          text-align: center;
                                          cursor: pointer;
                                        }
                                      `}
                                    >
                                      <Editor
                                        initialValue={JSON.stringify(
                                          textTranslation
                                        )}
                                        initialValueKey={JSON.stringify(
                                          textTranslation
                                        )}
                                        isReadOnly={true}
                                        isInline={true}
                                      />
                                    </div>
                                  ) : (
                                    <>
                                      Pause{" "}
                                      {getPauseSubscriptionDescription(
                                        [
                                          {
                                            type: o.type,
                                            interval: o.interval,
                                            intervalCount: o.interval_count,
                                          },
                                        ],
                                        false,
                                        language,
                                        renderPlaceholder,
                                        renderHighlight
                                      )}
                                    </>
                                  )}
                                </button>
                              );
                            }
                          )}
                        </div>
                      )}
                    {!!selectedPauseReason &&
                      selectedPauseOption?.type === "date" && (
                        <PauseDatesForm
                          startDate={pauseDate}
                          endDate={resumeDate}
                          onChangeStartDate={(value) => setPauseDate(value)}
                          onChangeEndDate={(value) => setResumeDate(value)}
                          minimumStartDate={
                            selectedPauseReason.minimumStartDate
                              ? Temporal.PlainDate.from(
                                  selectedPauseReason.minimumStartDate
                                )
                              : Temporal.Now.plainDateISO()
                          }
                          maximumStartDate={
                            selectedPauseReason.maximumStartDate
                              ? Temporal.PlainDate.from(
                                  selectedPauseReason.maximumStartDate
                                )
                              : pauseDate.add({ days: 180 })
                          }
                          maximumEndDate={
                            selectedPauseReason.maximumDays
                              ? pauseDate.add({
                                  days: selectedPauseReason.maximumDays,
                                })
                              : pauseDate.add({ days: 180 })
                          }
                        />
                      )}
                  </div>
                )}

                <div tw="mt-4">
                  <OfferButtons
                    offer={showOffer}
                    previewMode={isEditMode}
                    language={language}
                    defaultLanguage={defaultLanguage}
                    flowText={flowText}
                    onAccept={() =>
                      onAcceptOffer(
                        selectedOptionIndex,
                        pauseReasonCode,
                        selectedPauseOption?.type === "date"
                          ? pauseDate
                          : undefined,
                        selectedPauseOption?.type === "date"
                          ? resumeDate
                          : undefined
                      )
                    }
                    onDecline={() => {}}
                    isFinalStep={false}
                    showDeclineButton={false}
                    isDisabled={
                      offer?.type === "pause_subscription" &&
                      ((!!pauseReasons && !pauseReasonCode) ||
                        (selectedPauseOption?.type === "date" &&
                          (!pauseDate || !resumeDate)))
                    }
                  />
                </div>
              </div>
            )}
            {isEditMode && (
              <div tw="border-dashed border-divider border-2 rounded p-4 flex flex-col">
                <div tw="text-type-light">
                  <FontAwesomeIcon
                    icon={faGift}
                    color={theme`colors.gray.400`}
                    tw="mr-1"
                  />{" "}
                  Present an offer based on customer segments.
                </div>

                <div tw="bg-white mt-2">
                  {offerRules && (
                    <ReadOnlyOfferRules
                      offerRuleGroupId={intervention.offer_rule_group_id}
                      offerRules={offerRules}
                    />
                  )}
                </div>

                <div tw="mt-2 pl-1">
                  <StandardLinkButton
                    onClick={() => setOfferRulesModalIsOpen(true)}
                  >
                    Configure offer rules
                  </StandardLinkButton>
                </div>
              </div>
            )}
          </div>
        )}

        {offerRules && ruleManager && (
          <OfferRulesModal
            isOpen={offerRulesModalIsOpen}
            onClose={() => setOfferRulesModalIsOpen(false)}
            offerRuleGroupId={intervention.offer_rule_group_id}
            flowId={flowId}
            offerRules={offerRules}
            questions={[]}
            manager={ruleManager}
            flowText={flowText}
          />
        )}
      </div>
    </div>
  );
};

export default Intervention;
