import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import {
  DowngradeOfferFormQuery,
  language_enum,
  platform_enum,
} from "../../__generated__/graphql";
import EditorSelector from "../../common/editor2/EditorSelector";
import FieldCheckboxInput from "../../common/form/FieldCheckboxInput";
import FieldError from "../../common/form/FieldError";
import FieldHint from "../../common/form/FieldHint";
import FieldInput from "../../common/form/FieldInput";
import FieldLabel from "../../common/form/FieldLabel";
import FieldRow from "../../common/form/FieldRow";
import FieldRowBlock from "../../common/form/FieldRowBlock";
import FieldSetTitle from "../../common/form/FieldSetTitle";
import FormMode from "../../common/form/FormMode";
import ImageInput from "../../common/form/input/ImageInput";
import SelectInput from "../../common/form/input/SelectInput";
import TagsInput from "../../common/form/input/TagsInput";
import TextInput from "../../common/form/input/TextInput";
import useTranslatableForm, {
  TranslatedForms,
} from "../../common/form/useTranslatableForm";
import HelpIcon from "../../common/HelpIcon";
import IdDropdown from "../../common/IdDropdown";
import MetadataInput from "../../common/MetadataInput";
import PillRadio from "../../common/PillRadio";
import {
  PlatformFeature,
  platformHasFeature,
} from "../../common/platform/features";
import { useTranslations } from "../../common/translations/TranslationsProvider";
import useAccountFeatures from "../../common/useAccountFeatures";
import useFocusFirstEmptyInput from "../../common/useFocusFirstEmptyInput";
import LanguageRadio from "../flow/edit/LanguageRadio";
import getDefaultFormValues from "./forms/lib/getDefaultFormValues";
import getTranslatableFields from "./forms/lib/getTranslatableFields";
import { DowngradeOfferFormValues } from "./forms/types";
import useValidateHeadline from "./lib/useValidateHeadline";
import useValidateName from "./lib/useValidateName";
import validateMetadata from "./lib/validateMetadata";

interface DowngradeOfferFormProps {
  mode: FormMode;
  platform: platform_enum;
  onChange: (values: TranslatedForms<DowngradeOfferFormValues>) => void;
  onSubmit: (forms: TranslatedForms<DowngradeOfferFormValues>) => void;
  initialValues?: TranslatedForms<DowngradeOfferFormValues>;
  onChangeEditingLanguage: (language: language_enum) => void;
  isSubmitting: boolean;
  tags: string[];
}

const DowngradeOfferForm: React.FunctionComponent<DowngradeOfferFormProps> = ({
  mode,
  platform,
  onChange,
  onSubmit,
  initialValues,
  onChangeEditingLanguage,
  isSubmitting,
  tags,
}) => {
  const { defaultLanguage, enabledLanguages } = useTranslations();
  const defaultValues =
    initialValues ||
    getDefaultFormValues<DowngradeOfferFormValues>({
      type: "downgrade",
      enabledLanguages,
      platform,
    });

  const { data } = useQuery<DowngradeOfferFormQuery>(gql`
    query DowngradeOfferFormQuery {
      platform_plan(
        where: {
          _and: [
            { paper_code: { _is_null: false } }
            { platform_id: { _neq: "0" } }
          ]
        }
      ) {
        platform_id
        name
        paper_code
        code
        platform_product {
          name
        }
        platform_connection {
          platform
        }
        platform_plan_group {
          platform_id
          name
          code
        }
      }

      platform_connection {
        naviga_paper_codes {
          paper_code
        }
      }
    }
  `);

  const {
    register,
    watch,
    formState,
    setValue,
    handleSubmit,
    control,
    editingLanguage,
    setEditingLanguage,
    forms,
    trigger,
  } = useTranslatableForm<DowngradeOfferFormValues>({
    defaultValues,
    initialLanguage: defaultLanguage,
    languages: enabledLanguages,
    defaultLanguage,
    translatableFields: getTranslatableFields("downgrade"),
  });
  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  useFocusFirstEmptyInput(formRef);
  const validateName = useValidateName(formState.dirtyFields.name || false);
  const values = watch();
  const validateHeadline = useValidateHeadline(values.image);

  const { features } = useAccountFeatures();

  const [paperCode, setPaperCode] = useState<string>("");

  useEffect(() => {
    if (
      mode === "create" &&
      data?.platform_connection[0].naviga_paper_codes[0].paper_code
    ) {
      setPaperCode(
        data.platform_connection[0].naviga_paper_codes[0].paper_code
      );
    }
  }, [data?.platform_connection, mode]);

  useEffect(() => {
    if (mode === "edit") {
      const plan = data?.platform_plan.find(
        (plan) => plan.platform_id === initialValues?.en_us?.planId
      );

      if (plan?.paper_code) {
        setPaperCode(plan?.paper_code);
      }
    }
  }, [data?.platform_plan, initialValues?.en_us?.planId, mode, values.planId]);

  useEffect(() => {
    onChange(forms);
  }, [forms, onChange]);

  useEffect(() => {
    const plan = data?.platform_plan.find(
      (plan) => plan.platform_id === values.planId
    );
    if (plan) {
      setValue("planName", plan.name);
    }
  }, [data, setValue, values.planId]);

  return (
    <form
      id="create-offer"
      onChange={() => onChange(forms)}
      ref={(ref) => setFormRef(ref)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <fieldset disabled={isSubmitting}>
        <input {...register("type")} type="hidden" value="downgrade" />
        <FieldRow>
          <FieldLabel>
            <label htmlFor="name">Name</label>
          </FieldLabel>
          <FieldInput>
            <TextInput
              {...register("name", {
                required: true,
                validate: validateName,
              })}
              id="name"
              width="full"
              fieldError={formState.errors.name}
            />
            <FieldError error={formState.errors.name} />
          </FieldInput>
        </FieldRow>
        <FieldRow>
          <FieldLabel>Tags</FieldLabel>
          <FieldInput>
            <Controller
              control={control}
              name="tags"
              render={({ field }) => (
                <TagsInput
                  tags={tags}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldInput>
        </FieldRow>
        <FieldRow>
          <FieldLabel>
            <label>
              Allow override{" "}
              <HelpIcon content="By default, an offer will not be displayed to a subscriber if they have previously accepted another offer. This setting will allow this offer to override any previously accepted offers." />
            </label>
          </FieldLabel>
          <FieldCheckboxInput>
            <input
              {...register("allowOverride")}
              id="allowOverride"
              type="checkbox"
            />
            <label htmlFor="allowOverride">Override other offers</label>
          </FieldCheckboxInput>
        </FieldRow>

        <FieldSetTitle>Downgrade offer details</FieldSetTitle>
        <fieldset disabled={mode === "edit"}>
          {platform === "naviga" && (
            <FieldRow>
              <FieldLabel>
                <label htmlFor="planId">Paper code</label>
              </FieldLabel>
              <FieldInput>
                <SelectInput
                  width="sm"
                  value={paperCode}
                  onChange={(e) => setPaperCode(e.currentTarget.value)}
                >
                  {data?.platform_connection[0]?.naviga_paper_codes.map(
                    (code) => (
                      <option value={code.paper_code}>{code.paper_code}</option>
                    )
                  )}
                </SelectInput>
              </FieldInput>
            </FieldRow>
          )}
          <FieldRow>
            <FieldLabel>
              <label htmlFor="planId">Downgrade offer</label>
            </FieldLabel>
            <FieldInput>
              <Controller
                control={control}
                name="planId"
                rules={{ required: true }}
                render={({ field }) => (
                  <IdDropdown
                    ids={(data?.platform_plan || [])
                      .filter(
                        (plan) =>
                          platform !== "naviga" || plan.paper_code === paperCode
                      )
                      .map((plan) => ({
                        id: plan.platform_id,
                        displayId: plan.platform_plan_group?.code || undefined,
                        label: plan.name
                          ? `${plan.name} (${plan.platform_id})`
                          : plan.platform_id,
                      }))}
                    value={field.value}
                    onChange={field.onChange}
                    width="260px"
                    placeholder="Select a downgrade offer…"
                    fieldError={formState.errors.planId}
                  />
                )}
              />
              {!platformHasFeature(
                platform,
                PlatformFeature.DowngradeAtEndOfPeriod
              ) && (
                <FieldHint>
                  Subscriber will be downgraded immediately upon accepting the
                  offer.
                </FieldHint>
              )}
            </FieldInput>
          </FieldRow>
        </fieldset>
        {platformHasFeature(
          platform,
          PlatformFeature.DowngradeAtEndOfPeriod
        ) && (
          <FieldRow>
            <FieldLabel>
              <label>Downgrade at</label>
            </FieldLabel>
            <FieldInput>
              <Controller
                control={control}
                name="changeAt"
                render={({ field }) => (
                  <PillRadio
                    value={field.value}
                    options={[
                      {
                        label: "Immediately",
                        value: "immediately",
                        tooltip: "Downgrade immediately",
                      },
                      {
                        label: "End of period",
                        value: "period_end",
                        tooltip: "Downgrade at the end of the billing period",
                      },
                    ]}
                    onChange={field.onChange}
                  />
                )}
              />
            </FieldInput>
          </FieldRow>
        )}

        <FieldSetTitle>Offer {values.style}</FieldSetTitle>
        {enabledLanguages.length > 1 && (
          <LanguageRadio
            value={editingLanguage}
            languages={enabledLanguages}
            defaultLanguage={defaultLanguage}
            onChange={(language) => {
              setEditingLanguage(language);
              onChangeEditingLanguage(language);
            }}
          />
        )}
        <FieldRow>
          <FieldLabel>
            <label htmlFor="headline">Headline</label>
          </FieldLabel>
          <FieldInput>
            <TextInput
              {...register("headline", { validate: validateHeadline })}
              id="headline"
              width="full"
              fieldError={formState.errors.headline}
            />
            <FieldError error={formState.errors.headline} />
          </FieldInput>
        </FieldRow>
        <FieldRow>
          <FieldLabel>
            <label>Image</label>
          </FieldLabel>
          <FieldInput>
            <Controller
              control={control}
              name="image"
              render={({ field }) => (
                <ImageInput
                  value={field.value}
                  onChange={(logoUrl) => {
                    field.onChange(logoUrl);
                    if (formState.isSubmitted) {
                      trigger();
                    }
                  }}
                />
              )}
            />
          </FieldInput>
        </FieldRow>
        <FieldRowBlock>
          <FieldLabel>
            <label>Content</label>
          </FieldLabel>
          <Controller
            control={control}
            name="content"
            render={({ field }) => {
              const form = forms[editingLanguage];
              if (!form) {
                return <></>;
              }

              return (
                <EditorSelector
                  initialValueKey={editingLanguage}
                  isOfferContent
                  hasCustomProperties={!!features.custom_properties}
                  format={form.contentFormat}
                  value={form.content}
                  onChange={(value) => {
                    field.onChange(value);
                    onChange({
                      ...forms,
                      [editingLanguage]: {
                        ...forms[editingLanguage],
                        content: value,
                      },
                    });
                  }}
                />
              );
            }}
          />
        </FieldRowBlock>
        <FieldRow>
          <FieldLabel>
            <label>Button text</label>
          </FieldLabel>
          <FieldInput>
            <TextInput
              {...register("buttonText", { required: true })}
              id="buttonText"
              width="md"
              fieldError={formState.errors.buttonText}
            />
          </FieldInput>
        </FieldRow>
        <FieldSetTitle>Metadata</FieldSetTitle>
        <FieldRow>
          <FieldInput>
            <Controller
              control={control}
              name="metadata"
              render={({ field }) => (
                <MetadataInput value={field.value} onChange={field.onChange} />
              )}
              rules={{
                validate: validateMetadata,
              }}
            />
            <FieldError error={formState.errors.metadata} />
          </FieldInput>
        </FieldRow>
      </fieldset>
    </form>
  );
};

export default DowngradeOfferForm;
