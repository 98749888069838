import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "twin.macro";

import {
  FlowOfferFragment,
  language_enum,
} from "../../../__generated__/graphql";
import renderContent from "../../../common/editor/lib/renderContent";
import translationText from "../../../common/translationText";
import translationValue from "../../../common/translationValue";
import { usePropertyValues } from "../../properties/lib/propertyValues";
import { FlowError, FlowText } from "./lib/types";
import FlowButton from "./ui/FlowButton";

interface OfferButtonsProps {
  offer: FlowOfferFragment | null;
  flowText: FlowText;
  previewMode: boolean;
  language: language_enum;
  defaultLanguage: language_enum;
  isFinalStep: boolean;
  showDeclineButton?: boolean;
  showCloseX?: boolean;
  onAccept: () => void;
  onDecline: () => void;
  onDismiss?: () => void;
  isDismissing?: boolean | undefined;
  isDisabled?: boolean;
  error?: FlowError;
}

const XButton = tw.span``;

const OfferButtons: React.FunctionComponent<OfferButtonsProps> = ({
  offer,
  flowText,
  previewMode,
  language,
  defaultLanguage,
  isFinalStep,
  showDeclineButton = true,
  showCloseX = false,
  onAccept,
  onDecline,
  onDismiss = () => {},
  isDismissing = undefined,
  isDisabled = false,
  error,
}) => {
  const [isAccepting, setIsAccepting] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);

  const { propertyValues, propertyConfig, showPlaceholders } =
    usePropertyValues();

  const cancelText = isFinalStep
    ? renderContent(
        translationValue(flowText.cancel_button, language, defaultLanguage)
          .value,
        false,
        propertyValues,
        propertyConfig,
        showPlaceholders
      )
    : renderContent(
        translationValue(
          flowText.decline_offer_button,
          language,
          defaultLanguage
        ).value,
        false,
        propertyValues,
        propertyConfig,
        showPlaceholders
      );

  return (
    <>
      {showCloseX && (
        <XButton
          className="flow-button--x"
          onClick={() => {
            if (previewMode || isAccepting || isCanceling || isDismissing) {
              return;
            }

            setTimeout(() => {
              onDismiss();
            }, 100);
          }}
          data-vulcan="dismiss"
        >
          <FontAwesomeIcon icon={faTimes} />
        </XButton>
      )}
      <FlowButton
        buttonType="primary"
        onClick={() => {
          if (previewMode) {
            return;
          }
          setIsAccepting(true);

          setTimeout(() => {
            onAccept();
          }, 100);
        }}
        isLoading={isAccepting && !error}
        disabled={
          (isCanceling || isAccepting || isDismissing || isDisabled) &&
          (!error ||
            error.code === "PLATFORM_PERMANENT_ERROR" ||
            error.code === "UNKNOWN_ERROR")
        }
        data-vulcan="accept"
        className={classNames("flow-button--primary")}
      >
        {offer &&
        translationText(
          offer.button_text_translation,
          language,
          defaultLanguage
        )
          ? translationText(
              offer.button_text_translation,
              language,
              defaultLanguage
            )
          : "<button>"}
      </FlowButton>

      {showDeclineButton && offer?.goal === "retention" && (
        <FlowButton
          buttonType={isFinalStep ? "danger" : "default"}
          onClick={() => {
            if (previewMode) {
              return;
            }
            setIsCanceling(true);

            setTimeout(() => {
              onDecline();
            }, 100);
          }}
          isLoading={isCanceling}
          disabled={(isAccepting || isCanceling || isDismissing) && !error}
          data-vulcan="decline"
        >
          {cancelText}
        </FlowButton>
      )}

      {showDeclineButton && offer?.goal === "conversion" && (
        <>
          {offer?.style === "banner" && (
            <button
              type="button"
              className={classNames(
                "flow-button--decline",
                "flow-button--decline--thin"
              )}
              onClick={onDecline}
            >
              <FormattedMessage defaultMessage="Don’t show again" id="MWjOMp" />
            </button>
          )}
          <button
            type="button"
            className={classNames(
              "flow-button--decline",
              "flow-button--decline--wide"
            )}
            onClick={onDecline}
          >
            <FormattedMessage
              defaultMessage="Don’t show this again"
              id="HvFxVs"
            />
          </button>
        </>
      )}
    </>
  );
};

export default OfferButtons;
