import { gql, useQuery } from "@apollo/client";
import { useState } from "react";

import {
  SubscriberCampaignConfirmationQuery,
  SubscriberCampaignConfirmationQueryVariables,
} from "../../../__generated__/graphql";
import Editor from "../../../common/editor2/Editor";
import TranslationFragment from "../../../common/fragments/TranslationFragment";
import Spinner from "../../../common/Spinner";
import { useTranslations } from "../../../common/translations/TranslationsProvider";
import DefaultStyles from "../flow/DefaultStyles";
import FlowButton from "../flow/ui/FlowButton";

interface ConfirmationContentProps {
  offerToken: string;
  onReady: () => void;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationContent: React.FunctionComponent<
  ConfirmationContentProps
> = ({ offerToken, onReady, onConfirm, onCancel }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { translationValue, translationText } = useTranslations();

  const { data } = useQuery<
    SubscriberCampaignConfirmationQuery,
    SubscriberCampaignConfirmationQueryVariables
  >(
    gql`
      query SubscriberCampaignConfirmationQuery($offerToken: String!) {
        offer(where: { token: { _eq: $offerToken } }) {
          id
          confirmation_content_translation {
            ...TranslationFragment
          }
          confirmation_confirm_button_text_translation {
            ...TranslationFragment
          }
          confirmation_cancel_button_text_translation {
            ...TranslationFragment
          }
        }
      }
      ${TranslationFragment}
    `,
    {
      variables: {
        offerToken,
      },
      onCompleted: onReady,
    }
  );

  const offer = data?.offer.length ? data.offer[0] : undefined;
  const contentTranslationValue = translationValue(
    offer?.confirmation_content_translation
  ).value;
  const confirmButtonText =
    translationText(offer?.confirmation_confirm_button_text_translation) ||
    "Accept offer";
  const cancelButtonText =
    translationText(offer?.confirmation_cancel_button_text_translation) ||
    "Nevermind";

  const handleConfirm = () => {
    setIsSubmitting(true);
    onConfirm();
  };

  return (
    <>
      <DefaultStyles isEditMode={false} modal={true} fullScreen={false} />
      <div tw="p-6 bg-white rounded shadow-lg">
        {!!contentTranslationValue ? (
          <>
            <div>
              <Editor
                initialValue={JSON.stringify(contentTranslationValue)}
                isReadOnly={true}
              />
            </div>
            <div tw="mt-6 flex">
              <FlowButton
                type="button"
                buttonType="secondary"
                isLoading={isSubmitting}
                disabled={isSubmitting}
                onClick={handleConfirm}
                tw="w-full mr-4"
              >
                {confirmButtonText}
              </FlowButton>
              <FlowButton
                type="button"
                disabled={isSubmitting}
                onClick={onCancel}
                tw="w-full"
              >
                {cancelButtonText}
              </FlowButton>
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
};

export default ConfirmationContent;
