import { ApolloError, useApolloClient, useMutation } from "@apollo/client";
import { Temporal } from "@js-temporal/polyfill";
import gql from "graphql-tag";
import { cloneDeep } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactTestUtils from "react-dom/test-utils";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { major } from "semver";
import { isPresent } from "ts-is-present";

import {
  CompleteSubscriberFlowStatus,
  FlowAcceptOfferMutation,
  FlowAcceptOfferMutationVariables,
  FlowClickFlowActionMutation,
  FlowClickFlowActionMutationVariables,
  FlowCompleteFlowStepMutation,
  FlowCompleteFlowStepMutationVariables,
  FlowCompleteMutation,
  FlowCompleteMutationVariables,
  FlowDeclineOfferGroupMutation,
  FlowDeclineOfferGroupMutationVariables,
  FlowDeclineOfferMutation,
  FlowDeclineOfferMutationVariables,
  FlowDeflectionFragment,
  FlowDiscoverOfferQuery,
  FlowDiscoverOfferQueryVariables,
  FlowDiscoverTestOfferQuery,
  FlowDiscoverTestOfferQueryVariables,
  FlowInitializeMutation,
  FlowInitializeMutationVariables,
  FlowLogOfferGroupPresentedMutation,
  FlowLogOfferGroupPresentedMutationVariables,
  FlowLogOfferPresentedMutation,
  FlowLogOfferPresentedMutationVariables,
  FlowOfferFragment,
  FlowOfferGroupFragment,
  FlowStartFlowStepMutation,
  FlowStartFlowStepMutationVariables,
  FlowSubmitCustomSubscriberDetailsMutation,
  FlowSubmitCustomSubscriberDetailsMutationVariables,
  FlowSubmitFormAnswersMutation,
  FlowSubmitFormAnswersMutationVariables,
  FlowSubmitQuestionAnswerMutation,
  FlowSubmitQuestionAnswerMutationVariables,
  FlowSubscriptionFragment,
  FlowVersion as FlowVersionEnum,
  FormQuestionAnswer,
  language_enum,
  PauseReason,
  platform_enum,
  question_type_enum,
  subscriber_flow_status_enum,
} from "../../../__generated__/graphql";
import env from "../../../common/env";
import { ErrorCode } from "../../../common/errors/errorCodes";
import formatStepConditions from "../../../common/flow/formatStepConditions";
import getFlowObjectVersion from "../../../common/flow/getFlowObjectVersion";
import renderStep from "../../../common/flow/renderStep";
import useFlowByToken from "../../../common/flow/useFlowByToken";
import useFlowSteps from "../../../common/flow/useFlowSteps";
import useSubscriberFlowSegmentMatches from "../../../common/flow/useSubscriberFlowSegmentMatches";
import TheFlowPlatformConnectionFragment from "../../../common/fragments/FlowPlatformConnectionFragment";
import TheFlowSubscriptionFragment from "../../../common/fragments/FlowSubscriptionFragment";
import genToken from "../../../common/genToken";
import { isoToEnum } from "../../../common/languages";
import TranslationsProvider from "../../../common/translations/TranslationsProvider";
import translationText from "../../../common/translationText";
import translationValue from "../../../common/translationValue";
import useQueryParams from "../../../common/useQueryParams";
import { PropertyValuesProvider } from "../../properties/lib/propertyValues";
import { PropertyValues } from "../../properties/lib/types";
import { useUpsell } from "../../upgrade-account/UpsellProvider";
import AlreadyCanceled from "./AlreadyCanceled";
import FlowContent from "./FlowContent";
import FlowVersionProvider from "./FlowVersionProvider";
import debugLog from "./lib/debugLog";
import mapFlowText from "./lib/mapFlowText";
import questionIsSatisfied from "./lib/questionIsSatisfied";
import sanitizeAndFormatFlowQuestionAnswers from "./lib/sanitizeAndFormatFlowQuestionAnswers";
import {
  CustomSubscriberDetails,
  FlowDisplayMode,
  FlowError,
  FormStep,
} from "./lib/types";
import { QuestionAnswer } from "./lib/types";
import { transitionDuration } from "./lib/variables";

const getSkipOffers = (queryParams: URLSearchParams): string[] => {
  const skipOffers = queryParams.getAll("skipOffers");

  if (skipOffers.length) {
    return skipOffers;
  }

  // Handle bug introduced in @prosperstack/flow 0.10.0-beta.0 and fixed in 0.10.2
  const result = [];
  let index = 0;
  while (queryParams.has(`skipOffers[${index}]`)) {
    const value = queryParams.get(`skipOffers[${index}]`);
    if (value == null) {
      break;
    }
    result[index] = value;
    index++;
  }

  return result;
};

const Flow: React.FunctionComponent = () => {
  const { token } = useParams<{ token?: string }>();
  const queryParams = useQueryParams();
  const [isLoading, setIsLoading] = useState(true);
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [firstStepReady, setFirstStepReady] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [acknowledged, setAcknowledged] = useState(false);
  const [customSubscriberDetails, setCustomSubscriberDetails] =
    useState<CustomSubscriberDetails>();
  const [nextButtonEnabled, setNextButtonEnabled] = useState(true);
  const [doubleClickProtectionExpired, setDoubleClickProtectionExpired] =
    useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [questionAnswers, setQuestionAnswers] = useState<QuestionAnswer[]>([]);
  const [offer, setOffer] = useState<FlowOfferFragment | null>(null);
  const [nextOrderDate, setNextOrderDate] = useState<Temporal.PlainDate>();
  const [pauseReasons, setPauseReasons] = useState<PauseReason[]>();
  const [offerGroup, setOfferGroup] = useState<FlowOfferGroupFragment | null>(
    null
  );
  const [backgroundOffer, setBackgroundOffer] =
    useState<FlowOfferFragment | null>(null);

  const [swappableProducts, setSwappableProducts] =
    useState<FlowSubscriptionFragment | null>(null);
  const [offerStepId, setOfferStepId] = useState<string | null>(null);
  const [discoveringOffer, setDiscoveringOffer] = useState(false);
  const [firstOfferStepsHandled, setFirstOfferStepsHandled] = useState(false);
  const [presentedOfferStepIds, setPresentedOfferStepIds] = useState<string[]>(
    []
  );
  const [presentedDeflections, setPresentedDeflections] = useState<
    Record<string, FlowDeflectionFragment>
  >({});
  const [offerModalIsOpen, setOfferModalIsOpen] = useState(false);
  const [offerGroupModalIsOpen, setOfferGroupModalIsOpen] = useState(false);
  const [stepTransitioning, setStepTransitioning] = useState(false);

  const [mode, setMode] = useState<
    "flow" | "already_canceled" | "cannot_cancel"
  >("flow");

  const [status, setStatus] = useState<subscriber_flow_status_enum>(
    subscriber_flow_status_enum.in_progress
  );
  const [intakeFormDisplayErrorMessage, setIntakeFormDisplayErrorMessage] =
    useState<string>();
  const [previewCss, setPreviewCss] = useState<string | null>(null);
  const [previewGlobalCss, setPreviewGlobalCss] = useState<string | null>(null);
  const { enabled: isFreeMode, setUpgradeModalIsOpen: handleClickUpgrade } =
    useUpsell();

  const [cancelError, setCancelError] = useState<FlowError>();
  const [acceptOfferError, setAcceptOfferError] = useState<FlowError>();

  const apollo = useApolloClient();
  const [initializeSubscriberFlow, initializeSubscriberFlowResult] =
    useMutation<FlowInitializeMutation, FlowInitializeMutationVariables>(gql`
      mutation FlowInitializeMutation($input: InitializeSubscriberFlowInput!) {
        initializeSubscriberFlow(input: $input) {
          token
        }
      }
    `);
  const [completeSubscriberFlow] = useMutation<
    FlowCompleteMutation,
    FlowCompleteMutationVariables
  >(gql`
    mutation FlowCompleteMutation($input: CompleteSubscriberFlowInput!) {
      completeSubscriberFlow(input: $input) {
        flowSession
      }
    }
  `);
  const [acceptOffer] = useMutation<
    FlowAcceptOfferMutation,
    FlowAcceptOfferMutationVariables
  >(gql`
    mutation FlowAcceptOfferMutation(
      $subscriberFlowToken: String!
      $offerId: Int!
      $offerVariantId: Int
      $selectedOptionIndex: Int!
      $offerGroupId: Int
      $platformVariantId: String
      $rescheduleTo: String
      $pauseReasonCode: String
      $pauseAt: String
      $resumeAt: String
      $flowStepToken: String!
    ) {
      acceptOffer(
        input: {
          subscriberFlowToken: $subscriberFlowToken
          offerId: $offerId
          offerVariantId: $offerVariantId
          selectedOptionIndex: $selectedOptionIndex
          offerGroupId: $offerGroupId
          platformVariantId: $platformVariantId
          rescheduleTo: $rescheduleTo
          pauseReasonCode: $pauseReasonCode
          pauseAt: $pauseAt
          resumeAt: $resumeAt
          flowStepToken: $flowStepToken
        }
      ) {
        success
      }

      completeSubscriberFlow(
        input: { token: $subscriberFlowToken, status: saved }
      ) {
        flowSession
      }
    }
  `);
  const [declineOffer] = useMutation<
    FlowDeclineOfferMutation,
    FlowDeclineOfferMutationVariables
  >(gql`
    mutation FlowDeclineOfferMutation($input: DeclineOfferInput!) {
      declineOffer(input: $input) {
        success
      }
    }
  `);
  const [declineOfferGroup] = useMutation<
    FlowDeclineOfferGroupMutation,
    FlowDeclineOfferGroupMutationVariables
  >(gql`
    mutation FlowDeclineOfferGroupMutation($input: DeclineOfferGroupInput!) {
      declineOfferGroup(input: $input) {
        success
      }
    }
  `);
  const [startFlowStep] = useMutation<
    FlowStartFlowStepMutation,
    FlowStartFlowStepMutationVariables
  >(gql`
    mutation FlowStartFlowStepMutation($input: StartFlowStepInput!) {
      startFlowStep(input: $input) {
        success
      }
    }
  `);
  const [stepsStarted, setStepsStarted] = useState<Record<string, boolean>>({});
  const [completeFlowStep] = useMutation<
    FlowCompleteFlowStepMutation,
    FlowCompleteFlowStepMutationVariables
  >(gql`
    mutation FlowCompleteFlowStepMutation($input: CompleteFlowStepInput!) {
      completeFlowStep(input: $input) {
        success
      }
    }
  `);
  const [submitCustomSubscriberDetails] = useMutation<
    FlowSubmitCustomSubscriberDetailsMutation,
    FlowSubmitCustomSubscriberDetailsMutationVariables
  >(gql`
    mutation FlowSubmitCustomSubscriberDetailsMutation(
      $input: SubmitCustomSubscriberDetailsInput!
    ) {
      submitCustomSubscriberDetails(input: $input) {
        success
        rerouted
        message
        prevented
        subscriber_flow {
          id
          subscriber {
            id
            name
            email
            subscriber_properties {
              subscriber_id
              property_id
              value
              property {
                id
                name
                type
                format
              }
            }
          }
          subscription {
            id
            subscription_properties {
              subscription_id
              property_id
              value
              property {
                id
                name
                type
                format
              }
            }
          }
        }
      }
    }
  `);
  const [clickFlowAction] = useMutation<
    FlowClickFlowActionMutation,
    FlowClickFlowActionMutationVariables
  >(gql`
    mutation FlowClickFlowActionMutation($input: ClickFlowActionInput!) {
      clickFlowAction(input: $input) {
        success
        newSubscriberFlowToken
      }
    }
  `);

  const stripeSubscriptionId = queryParams.get("stripeSubscriptionId");

  const previewMode = queryParams.get("previewMode") === "true";
  const previewVersion =
    queryParams.get("previewVersion") === "published" ? "published" : "draft";
  const testMode = previewMode || queryParams.get("testMode") === "true";
  const displayMode: FlowDisplayMode =
    queryParams.get("displayMode") === "modal" ? "modal" : "full_screen";
  const skipOffers = getSkipOffers(queryParams);
  const flowVersion = previewMode ? previewVersion : "published";

  useEffect(() => {
    const listener = (e: MessageEvent) => {
      if (e.origin !== env("REACT_APP_APP_URL")) {
        return;
      }

      switch (e.data.message) {
        case "updateCss":
          setPreviewCss(e.data.css);
          setPreviewGlobalCss(e.data.globalCss);
          break;
      }
    };

    window.addEventListener("message", listener);

    return () => {
      window.removeEventListener("message", listener);
    };
  }, []);

  useEffect(() => {
    if (
      initializeSubscriberFlowResult.called ||
      !token ||
      !stripeSubscriptionId ||
      testMode
    ) {
      return;
    }

    initializeSubscriberFlow({
      variables: {
        input: {
          flowToken: token,
          stripeSubscriptionId,
        },
      },
    });
  }, [
    initializeSubscriberFlow,
    initializeSubscriberFlowResult.called,
    stripeSubscriptionId,
    testMode,
    token,
  ]);

  const {
    data: flowData,
    loading: flowLoading,
    refetch: refetchFlow,
  } = useFlowByToken(token || "", flowVersion === "draft");

  const isoLanguage = queryParams.get("language");

  const previewSegmentsParam = queryParams.get("previewSegments");

  const previewSegments = useMemo<number[]>(
    () => (previewSegmentsParam ? JSON.parse(previewSegmentsParam) : []),
    [previewSegmentsParam]
  );

  const previewPropertyValuesParam = queryParams.get("previewCustomProperties");

  const previewPropertyValues = useMemo<PropertyValues>(
    () =>
      previewPropertyValuesParam ? JSON.parse(previewPropertyValuesParam) : {},
    [previewPropertyValuesParam]
  );

  const {
    data: segmentMatchesData,
    loading: segmentMatchesLoading,
    refetch: refetchSegmentMatches,
  } = useSubscriberFlowSegmentMatches(
    token || "",
    flowVersion,
    previewPropertyValues,
    previewSegments
  );

  useEffect(() => {
    setIsLoading(
      flowLoading ||
        segmentMatchesLoading ||
        (!testMode &&
          !!stripeSubscriptionId &&
          !initializeSubscriberFlowResult.data?.initializeSubscriberFlow
            .token) ||
        !firstStepReady
    );
  }, [
    flowLoading,
    initializeSubscriberFlowResult.data,
    segmentMatchesLoading,
    stripeSubscriptionId,
    testMode,
    firstStepReady,
  ]);

  const flow = (flowData?.flow.length && flowData.flow[0]) || undefined;
  const subscriberFlow = flow?.subscriber_flows.length
    ? flow.subscriber_flows[0]
    : undefined;

  const majorVersion = previewMode
    ? 2
    : subscriberFlow?.version
    ? major(subscriberFlow.version)
    : undefined;
  const redirectUri = flow?.redirect_uri;

  useEffect(() => {
    if (
      flow &&
      flow.prevent_if_canceled &&
      flow.prevent_if_canceled_translation &&
      subscriberFlow?.status === "aborted"
    ) {
      setMode("already_canceled");
    } else if (subscriberFlow?.status === "prevented") {
      setMode("cannot_cancel");
    }
  }, [flow, subscriberFlow?.status]);

  useEffect(() => {
    if (!finishedLoading && !isLoading && firstOfferStepsHandled) {
      if (testMode && typeof majorVersion === "undefined") {
        window.parent.postMessage({ message: "loaded" }, "*");
        return;
      }

      if (majorVersion && majorVersion >= 2) {
        window.parent.postMessage({ message: "loaded" }, "*");
      } else {
        window.parent.postMessage(
          JSON.stringify({
            message: "loaded",
          }),
          "*"
        );
      }

      setFinishedLoading(true);
    }
  }, [
    firstOfferStepsHandled,
    finishedLoading,
    isLoading,
    majorVersion,
    testMode,
  ]);

  const defaultLanguage = flow?.default_language || language_enum.en_us;
  const language =
    (isoLanguage ? isoToEnum(isoLanguage) : defaultLanguage) || defaultLanguage;
  const enabledLanguages = flow
    ? flow.flow_languages.map((flowLanguage) => flowLanguage.language)
    : [defaultLanguage];

  const promptForSubscriberDetails =
    !subscriberFlow?.subscriber.name &&
    !subscriberFlow?.subscriber.email &&
    !!flow?.account.prompt_for_subscriber_details;

  const segmentMatches = segmentMatchesData?.subscriberFlowSegmentMatches
    .segmentIds.length
    ? [...segmentMatchesData?.subscriberFlowSegmentMatches.segmentIds]
    : [];
  const segmentGroupMatches = segmentMatchesData?.subscriberFlowSegmentMatches
    .segmentGroupIds?.length
    ? [...segmentMatchesData.subscriberFlowSegmentMatches.segmentGroupIds]
    : [];

  debugLog("segmentMatches", segmentMatches);
  debugLog("segmentGroupMatches", segmentGroupMatches);

  const version = flow && getFlowObjectVersion(flow, flowVersion);
  const flowSteps = (version?.flow_version_flow_steps || [])
    .map((v) => v.flow_step)
    .filter(isPresent);

  const questions = flowSteps
    .flatMap((s) =>
      !!s.flow_step_question?.question
        ? s.flow_step_question.question
        : !!s.flow_step_form?.form
        ? getFlowObjectVersion(
            s.flow_step_form.form,
            flowVersion
          ).form_version_questions.map((v) => v.question)
        : undefined
    )
    .filter(isPresent);

  const stepConditions =
    (flowSteps &&
      formatStepConditions({
        steps: flowSteps,
        matchedSegmentIds: segmentMatches,
        matchedSegmentGroupIds: segmentGroupMatches,
        flowVersion,
      })) ||
    [];

  debugLog("stepConditions", stepConditions);

  const subscriberProperties =
    subscriberFlow?.subscriber.subscriber_properties || [];
  const subscriptionProperties =
    subscriberFlow?.subscription.subscription_properties || [];
  const segmentConditionProperties =
    flowData?.flow[0]?.subscriber_flows[0]?.segment_values || {};

  const propertyValues: PropertyValues = [
    ...subscriberProperties,
    ...subscriptionProperties,
  ].reduce(
    (prev, curr) => ({
      ...prev,
      [curr.property_id.toString()]: curr.value,
    }),
    (() => {
      const names = (subscriberFlow?.subscriber.name || "").split(" ");

      return {
        ...(names.length > 0 && {
          first_name: names[0],
        }),
        ...(names.length > 1 && {
          last_name: names[names.length - 1],
        }),
        ...(subscriberFlow?.subscriber.email && {
          email: subscriberFlow.subscriber.email,
        }),
        ...segmentConditionProperties,
      };
    })()
  );

  const propertyConfig = (flow?.account.properties || []).reduce(
    (prev, current) => ({
      ...prev,
      [current.id]: {
        name: current.name,
        type: current.type,
        numberFormat: current.format,
      },
    }),
    {}
  );

  const steps = useFlowSteps({
    isLoading: segmentMatchesLoading,
    isEditing: false,
    forceIncludeConditional: false,
    flow,
    stepConditions,
    questionAnswers,
    language,
    defaultLanguage: flow?.default_language,
    flowVersion,
    offerRules: null,
    status,
    clientMajorVersion: majorVersion || 0,
    promptForSubscriberDetails,
    segmentMatches,
    segmentGroupMatches,
    presentedDeflections,
    propertyValues: previewMode ? previewPropertyValues : propertyValues,
  });

  debugLog("steps", steps);

  const currentStep = steps[currentStepIndex];
  const nextStep =
    steps.length >= currentStepIndex + 1
      ? steps[currentStepIndex + 1]
      : undefined;
  const finalStep = [...steps]
    .reverse()
    .find(
      (step) =>
        step.type === "form" ||
        step.type === "question" ||
        step.type === "deflectionRuleGroup" ||
        step.type === "acknowledgementGroup" ||
        step.type === "offerRuleGroup"
    );

  const finalFormStep = [...steps]
    .reverse()
    .find((step) => step.type === "form");

  const finalQuestionStep = [...steps]
    .reverse()
    .find((step) => step.type === "question");

  useEffect(() => {
    if (!currentStep) {
      return;
    }

    const subscriberFlowToken = !stripeSubscriptionId
      ? token
      : initializeSubscriberFlowResult.data?.initializeSubscriberFlow.token;

    if (!subscriberFlowToken) {
      return;
    }

    if (stepsStarted[currentStep.id]) {
      return;
    }

    setStepsStarted({
      ...stepsStarted,
      [currentStep.id]: true,
    });

    if (
      !testMode &&
      currentStep.type !== "subscriberDetailsForm" &&
      currentStep.type !== "offerRuleGroup"
    ) {
      startFlowStep({
        variables: {
          input: {
            subscriberFlowToken,
            flowStepToken: currentStep.id,
            deflectionId:
              currentStep.type === "deflectionRuleGroup" &&
              !!currentStep.deflection
                ? currentStep.deflection.id
                : null,
          },
        },
      });
    }
  }, [
    currentStep,
    initializeSubscriberFlowResult.data,
    startFlowStep,
    stepsStarted,
    stripeSubscriptionId,
    testMode,
    token,
  ]);

  useEffect(() => {
    if (
      !currentStep ||
      currentStep.type !== "offerRuleGroup" ||
      offer?.style !== "step"
    ) {
      return;
    }

    if (presentedOfferStepIds.includes(currentStep.id)) {
      return;
    }

    setPresentedOfferStepIds([...presentedOfferStepIds, currentStep.id]);
  }, [currentStep, offer, presentedOfferStepIds]);

  const submitQuestionAnswer = async (flowStepToken: string) => {
    if (testMode) {
      return;
    }

    const subscriberFlowToken = !stripeSubscriptionId
      ? token
      : initializeSubscriberFlowResult.data?.initializeSubscriberFlow.token;

    if (!subscriberFlowToken) {
      throw new Error("No subscriber flow token");
    }

    const flowStep = steps.find((s) => s.id === flowStepToken);
    if (!flowStep) {
      throw new Error(`Flow step ${flowStepToken} not found`);
    }

    const question =
      flowStep.type === "question" ? flowStep.question : undefined;
    if (!question) {
      throw new Error(`Flow step ${flowStepToken} has no question`);
    }

    const answers = sanitizeAndFormatFlowQuestionAnswers(
      questionAnswers,
      steps,
      flowVersion
    );

    const answer = answers.find((a) => a.id === question.id);
    if (!answer) {
      throw new Error(`No answer found for question ${question.id}`);
    }

    await apollo.mutate<
      FlowSubmitQuestionAnswerMutation,
      FlowSubmitQuestionAnswerMutationVariables
    >({
      mutation: gql`
        mutation FlowSubmitQuestionAnswerMutation(
          $input: SubmitQuestionAnswerInput!
        ) {
          submitQuestionAnswer(input: $input) {
            success
          }
        }
      `,
      variables: {
        input: {
          subscriberFlowToken,
          flowStepToken,
          flowVersion:
            flowVersion === "draft"
              ? FlowVersionEnum.draft
              : FlowVersionEnum.published,
          value:
            answer.type === question_type_enum.radio && answer.specify
              ? JSON.stringify({ value: answer.value, specify: answer.specify })
              : JSON.stringify(answer.value),
        },
      },
    });
  };

  const submitFormAnswers = async (flowStepToken: string) => {
    if (testMode) {
      return;
    }

    const subscriberFlowToken = !stripeSubscriptionId
      ? token
      : initializeSubscriberFlowResult.data?.initializeSubscriberFlow.token;
    if (!subscriberFlowToken) {
      throw new Error("No subscriber flow token");
    }

    const flowStep = steps.find((s) => s.id === flowStepToken);
    if (!flowStep) {
      throw new Error(`Flow step ${flowStepToken} not found`);
    }

    const form = flowStep.type === "form" ? flowStep.form : undefined;
    if (!form) {
      throw new Error(`Flow step ${flowStepToken} has no form`);
    }

    const presentedQuestions = (flowStep as FormStep).questions
      .filter((q) => q.isEnabled && !q.isHidden)
      .map((q) => q.question);
    if (!presentedQuestions.length) {
      throw new Error(`Flow step ${flowStepToken} has no questions`);
    }

    const answers = sanitizeAndFormatFlowQuestionAnswers(
      questionAnswers,
      steps,
      flowVersion
    ).filter((a) => presentedQuestions.map((q) => q.id).includes(a.id));
    if (!answers.length) {
      throw new Error(`Flow step ${flowStepToken} has no form answers`);
    }

    await apollo.mutate<
      FlowSubmitFormAnswersMutation,
      FlowSubmitFormAnswersMutationVariables
    >({
      mutation: gql`
        mutation FlowSubmitFormAnswersMutation(
          $input: SubmitFormAnswersInput!
        ) {
          submitFormAnswers(input: $input) {
            success
          }
        }
      `,
      variables: {
        input: {
          subscriberFlowToken,
          flowStepToken,
          flowVersion:
            flowVersion === "draft"
              ? FlowVersionEnum.draft
              : FlowVersionEnum.published,
          questionAnswers: answers.map(
            (answer) =>
              ({
                questionId: answer.id,
                value:
                  answer.type === question_type_enum.radio && answer.specify
                    ? JSON.stringify({
                        value: answer.value,
                        specify: answer.specify,
                      })
                    : JSON.stringify(answer.value),
              } as FormQuestionAnswer)
          ),
        },
      },
    });
  };

  const discoverOffer = useCallback(
    async (stepId: string, subscriberFlowToken: string) => {
      const result = await apollo.query<
        FlowDiscoverOfferQuery,
        FlowDiscoverOfferQueryVariables
      >({
        query: gql`
          query FlowDiscoverOfferQuery($input: SubscriberFlowOfferInput!) {
            subscriberFlowOffer(input: $input) {
              flowOffer
              flowOfferGroup
              subscription {
                ...FlowSubscriptionFragment
              }
              platform_connection {
                ...FlowPlatformConnectionFragment
              }
              nextOrderDate
              pauseReasons {
                code
                reason
                minimumStartDate
                maximumStartDate
                maximumDays
              }
            }
          }
          ${TheFlowSubscriptionFragment}
          ${TheFlowPlatformConnectionFragment}
        `,
        variables: {
          input: {
            subscriberFlowToken,
            flowStepToken: stepId,
            skipOffers,
            flowVersion:
              flowVersion === "draft"
                ? FlowVersionEnum.draft
                : FlowVersionEnum.published,
          },
        },
      });

      const platform =
        result.data?.subscriberFlowOffer.platform_connection?.platform;

      if (
        platform === platform_enum.bold &&
        result.data?.subscriberFlowOffer.subscription
      ) {
        setSwappableProducts(result.data?.subscriberFlowOffer.subscription);
      } else if (
        platform === platform_enum.recharge &&
        result.data?.subscriberFlowOffer.subscription &&
        result.data?.subscriberFlowOffer.platform_connection
          ?.platform_ecommerce_products.length
      ) {
        setSwappableProducts({
          __typename: "subscription",
          id: 1,
          platform_subscription: {
            __typename: "platform_subscription",
            platform_id:
              result.data?.subscriberFlowOffer.subscription
                .platform_subscription?.platform_id || "1",
            swappable_ecommerce_products:
              result.data?.subscriberFlowOffer.platform_connection?.platform_ecommerce_products.map(
                (p) => ({
                  __typename:
                    "platform_subscription_plan_swappable_ecommerce_product",
                  platform_ecommerce_product: p,
                })
              ) || [],
            platform_subscription_plans:
              result.data?.subscriberFlowOffer.subscription
                .platform_subscription?.platform_subscription_plans || [],
          },
        });
      }

      let subscriberFlowOffer: FlowDiscoverOfferQuery["subscriberFlowOffer"] & {
        offer?: FlowOfferFragment;
        offer_group?: FlowOfferGroupFragment;
      } = result.data.subscriberFlowOffer;

      if (subscriberFlowOffer.flowOffer || subscriberFlowOffer.flowOfferGroup) {
        subscriberFlowOffer = {
          ...subscriberFlowOffer,
          ...(subscriberFlowOffer.flowOffer && {
            offer: JSON.parse(subscriberFlowOffer.flowOffer),
          }),
          ...(subscriberFlowOffer.flowOfferGroup && {
            offer_group: JSON.parse(subscriberFlowOffer.flowOfferGroup),
          }),
        };
      }

      return subscriberFlowOffer;
    },
    [apollo, flowVersion, skipOffers]
  );

  const discoverTestOffer = useCallback(
    async (stepId: string) => {
      if (!token) {
        return null;
      }

      const answersData = JSON.stringify(
        sanitizeAndFormatFlowQuestionAnswers(
          questionAnswers,
          steps,
          flowVersion
        )
      );

      const result = await apollo.query<
        FlowDiscoverTestOfferQuery,
        FlowDiscoverTestOfferQueryVariables
      >({
        query: gql`
          query FlowDiscoverTestOfferQuery($input: FlowTestOfferInput!) {
            flowTestOffer(input: $input) {
              flowOffer
              flowOfferGroup
              platform_connection {
                ...FlowPlatformConnectionFragment
              }
              nextOrderDate
              pauseReasons {
                code
                reason
                minimumStartDate
                maximumStartDate
                maximumDays
              }
            }
          }
          ${TheFlowPlatformConnectionFragment}
        `,
        variables: {
          input: {
            flowStepToken: stepId,
            answersData,
            skipOffers,
            flowVersion:
              flowVersion === "draft"
                ? FlowVersionEnum.draft
                : FlowVersionEnum.published,
            previewSegmentIds: previewSegments,
            previewPropertyValues: previewPropertyValues,
          },
        },
      });

      if (
        result.data?.flowTestOffer.platform_connection
          ?.platform_ecommerce_products.length
      ) {
        setSwappableProducts({
          __typename: "subscription",
          id: 1,
          platform_subscription: {
            __typename: "platform_subscription",
            platform_id: "1",
            swappable_ecommerce_products:
              result.data?.flowTestOffer.platform_connection?.platform_ecommerce_products.map(
                (p) => ({
                  __typename:
                    "platform_subscription_plan_swappable_ecommerce_product",
                  platform_ecommerce_product: p,
                })
              ) || [],
            platform_subscription_plans: [
              {
                __typename: "platform_subscription_plan",
                platform_variant: {
                  __typename: "platform_variant",
                  platform_id: "test",
                  platform_variant_options: [],
                  price: "9.99",
                  platform_ecommerce_product: {
                    __typename: "platform_ecommerce_product",
                    name: "Example Product",
                    platform_id: "test",
                  },
                },
              },
            ],
          },
        });
      }

      let flowTestOffer: FlowDiscoverTestOfferQuery["flowTestOffer"] & {
        offer?: FlowOfferFragment;
        offer_group?: FlowOfferGroupFragment;
      } = result.data.flowTestOffer;

      if (flowTestOffer.flowOffer || flowTestOffer.flowOfferGroup) {
        flowTestOffer = {
          ...flowTestOffer,
          ...(flowTestOffer.flowOffer && {
            offer: JSON.parse(flowTestOffer.flowOffer),
          }),
          ...(flowTestOffer.flowOfferGroup && {
            offer_group: JSON.parse(flowTestOffer.flowOfferGroup),
          }),
        };
      }

      return flowTestOffer;
    },
    [
      apollo,
      flowVersion,
      previewPropertyValues,
      previewSegments,
      questionAnswers,
      skipOffers,
      steps,
      token,
    ]
  );

  const logOfferPresented = useCallback(
    async (offerId: number, subscriberFlowToken: string) => {
      if (testMode) {
        return;
      }

      await apollo.mutate<
        FlowLogOfferPresentedMutation,
        FlowLogOfferPresentedMutationVariables
      >({
        mutation: gql`
          mutation FlowLogOfferPresentedMutation(
            $input: LogOfferPresentedInput!
          ) {
            logOfferPresented(input: $input) {
              success
            }
          }
        `,
        variables: {
          input: {
            offerId,
            subscriberFlowToken,
          },
        },
      });
    },
    [apollo, testMode]
  );

  const logOfferGroupPresented = useCallback(
    async (offerGroupId: number, subscriberFlowToken: string) => {
      if (testMode) {
        return;
      }

      await apollo.mutate<
        FlowLogOfferGroupPresentedMutation,
        FlowLogOfferGroupPresentedMutationVariables
      >({
        mutation: gql`
          mutation FlowLogOfferGroupPresentedMutation(
            $input: LogOfferGroupPresentedInput!
          ) {
            logOfferGroupPresented(input: $input) {
              success
            }
          }
        `,
        variables: {
          input: {
            offerGroupId,
            subscriberFlowToken,
          },
        },
      });
    },
    [apollo, testMode]
  );

  const testModeSession = useCallback(
    (status: string) => {
      const now = new Date().toISOString();

      const answers = sanitizeAndFormatFlowQuestionAnswers(
        questionAnswers,
        steps,
        flowVersion
      ).map((answer) => {
        const question = questions.find((q) => q.id === answer.id);
        if (!question) {
          throw new Error();
        }

        const questionVersion = getFlowObjectVersion(question, flowVersion);

        const value: string | Array<{ id?: string; text: string }> | null =
          typeof answer.value === "string"
            ? answer.value
            : !answer.value || answer.value.length === 0
            ? null
            : answer.value.map((value) => {
                const option =
                  questionVersion.question_version_question_options.find(
                    (o) => value && o.question_option_id === value.id
                  )?.question_option;

                if (!option) {
                  throw new Error();
                }

                const optionVersion = getFlowObjectVersion(option, flowVersion);

                return {
                  id: option.token,
                  text: translationText(
                    optionVersion.title_translation,
                    language,
                    defaultLanguage
                  ),
                };
              });

        if (
          value &&
          typeof value !== "string" &&
          answer.type === "radio" &&
          answer.specify
        ) {
          value.push({ text: answer.specify });
        }

        return {
          question: {
            id: question.token,
            type: question.type === "radio" ? "multiple_choice" : question.type,
            text: translationText(
              questionVersion.title_translation,
              language,
              defaultLanguage
            ),
          },
          value,
        };
      });

      return {
        id: `sess_${genToken()}`,
        status,
        created_at: now,
        updated_at: now,
        subscriber: {
          id: `subr_${genToken()}`,
          name: "Test Subscriber",
          email: "test@example.com",
          status:
            status === "canceled"
              ? "canceled"
              : status === "saved"
              ? "saved"
              : "active",
          created_at: now,
          updated_at: now,
        },
        offers_presented: offer
          ? [
              {
                id: offer.token,
                type: offer.type,
                name: offer.name,
                created_at: offer.created_at,
                updated_at: offer.updated_at,
              },
            ]
          : [],
        offer_accepted:
          status === "saved" && offer
            ? {
                id: offer.token,
                type: offer.type,
                name: offer.name,
                created_at: offer.created_at,
                updated_at: offer.updated_at,
              }
            : null,
        answers,
      };
    },
    [
      defaultLanguage,
      flowVersion,
      language,
      offer,
      questionAnswers,
      questions,
      steps,
    ]
  );

  const handleAcceptGroupOffer = async (
    selectedOptionIndex: number,
    offerId: string,
    pauseReasonCode: string | null = null,
    pauseAt: Temporal.PlainDate | null = null,
    resumeAt: Temporal.PlainDate | null = null
  ) => {
    if (!offerGroup) {
      throw new Error();
    }

    setAcceptOfferError(undefined);

    const innerOffer = offerGroup?.offer_group_offers.find(
      ({ offer }) => offer?.token === offerId
    )?.offer;

    if (!innerOffer || !offerStepId) {
      throw new Error();
    }

    const thisStepIndex = steps.findIndex((s) => s.id === offerStepId);
    const thisStep = steps[thisStepIndex];

    const subscriberFlowToken = !stripeSubscriptionId
      ? token
      : initializeSubscriberFlowResult.data?.initializeSubscriberFlow.token;

    if (!subscriberFlowToken) {
      throw new Error("No subscriber flow token");
    }

    let flowSession: string | undefined = undefined;

    if (!testMode) {
      await completeFlowStep({
        variables: {
          input: { subscriberFlowToken, flowStepToken: thisStep.id },
        },
      });

      try {
        const result = await acceptOffer({
          variables: {
            subscriberFlowToken,
            offerId: innerOffer.id,
            selectedOptionIndex,
            offerGroupId: offerGroup.id,
            pauseReasonCode,
            pauseAt: pauseAt ? pauseAt.toString() : null,
            resumeAt: resumeAt ? resumeAt.toString() : null,
            flowStepToken: thisStep.id,
          },
        });

        flowSession = result.data?.completeSubscriberFlow.flowSession;
      } catch (e) {
        if (e instanceof ApolloError) {
          const firstError = e.graphQLErrors[0];
          if (firstError?.extensions.code) {
            setAcceptOfferError({
              type: "accept_offer",
              code: firstError.extensions.code as ErrorCode,
            });

            return;
          }

          if (!firstError) {
            throw e;
          }
        }
      }
    } else {
      flowSession = JSON.stringify(testModeSession("saved"));
    }

    setStatus(subscriber_flow_status_enum.saved);

    if (testMode || (majorVersion && majorVersion >= 2)) {
      let close = true;

      const confirmationStep = steps.find(
        (step) => step.type === "confirmation"
      );
      if (confirmationStep?.type === "confirmation") {
        const confirmationVersion = getFlowObjectVersion(
          confirmationStep.confirmation,
          flowVersion
        );
        if (confirmationVersion.show_on_save) {
          close = false;

          setOfferModalIsOpen(false);
          setOfferGroupModalIsOpen(false);
          handleConfirmationStep(confirmationStep.id);
        }
      }

      const payload: Record<string, any> = {
        status: "saved",
        flowSession: flowSession ? JSON.parse(flowSession) : undefined,
      };

      if (redirectUri) {
        payload.redirectUri = redirectUri;
      }

      window.parent.postMessage(
        {
          message: "complete",
          close,
          payload,
        },
        "*"
      );
    } else {
      // < v1.0.0
      window.parent.postMessage(
        JSON.stringify({
          message: "saved",
          offer: {
            id: innerOffer.token,
            name: innerOffer.name,
          },
        }),
        "*"
      );

      // >= v1.0.0
      window.parent.postMessage(
        JSON.stringify({
          message: "complete",
          payload: {
            status: "saved",
            flowSession: flowSession ? JSON.parse(flowSession) : undefined,
          },
        }),
        "*"
      );
    }
  };

  const handleAcceptOffer = async (
    selectedOptionIndex: number,
    groupOffer: FlowOfferFragment | null = null,
    selectedVariantId: string | null = null,
    rescheduleTo: Temporal.PlainDate | null = null,
    pauseReasonCode: string | null = null,
    pauseAt: Temporal.PlainDate | null = null,
    resumeAt: Temporal.PlainDate | null = null
  ) => {
    setAcceptOfferError(undefined);

    const acceptedOffer = groupOffer || offer;
    if (!acceptedOffer || !offerStepId) {
      throw new Error();
    }

    const thisStepIndex = steps.findIndex((s) => s.id === offerStepId);
    const thisStep = steps[thisStepIndex];

    const subscriberFlowToken = !stripeSubscriptionId
      ? token
      : initializeSubscriberFlowResult.data?.initializeSubscriberFlow.token;

    if (!subscriberFlowToken) {
      throw new Error("No subscriber flow token");
    }

    let flowSession: string | undefined = undefined;

    if (!testMode) {
      await completeFlowStep({
        variables: {
          input: { subscriberFlowToken, flowStepToken: thisStep.id },
        },
      });

      try {
        const result = await acceptOffer({
          variables: {
            subscriberFlowToken,
            offerId: acceptedOffer.id,
            selectedOptionIndex,
            platformVariantId: selectedVariantId,
            rescheduleTo: rescheduleTo ? rescheduleTo.toString() : null,
            pauseReasonCode,
            pauseAt: pauseAt ? pauseAt.toString() : null,
            resumeAt: resumeAt ? resumeAt.toString() : null,
            flowStepToken: thisStep.id,
          },
        });

        flowSession = result.data?.completeSubscriberFlow.flowSession;
      } catch (e) {
        if (e instanceof ApolloError) {
          const firstError = e.graphQLErrors[0];
          if (firstError?.extensions.code) {
            setAcceptOfferError({
              type: "accept_offer",
              code: firstError.extensions.code as ErrorCode,
            });

            return;
          }

          if (!firstError) {
            throw e;
          }
        }
      }
    } else {
      flowSession = JSON.stringify(testModeSession("saved"));
    }

    setStatus(subscriber_flow_status_enum.saved);

    if (testMode || (majorVersion && majorVersion >= 2)) {
      let close = true;

      const confirmationStep = steps.find(
        (step) => step.type === "confirmation"
      );
      if (confirmationStep?.type === "confirmation") {
        const confirmationVersion = getFlowObjectVersion(
          confirmationStep.confirmation,
          flowVersion
        );
        if (confirmationVersion.show_on_save) {
          close = false;

          setOfferModalIsOpen(false);
          setOfferGroupModalIsOpen(false);
          handleConfirmationStep(confirmationStep.id);
        }
      }

      const payload: Record<string, any> = {
        status: "saved",
        flowSession: flowSession ? JSON.parse(flowSession) : undefined,
      };

      if (redirectUri) {
        payload.redirectUri = redirectUri;
      }

      window.parent.postMessage(
        {
          message: "complete",
          close,
          payload,
        },
        "*"
      );
    } else {
      // < v1.0.0
      window.parent.postMessage(
        JSON.stringify({
          message: "saved",
          offer: {
            id: acceptedOffer.token,
            name: acceptedOffer.name,
          },
        }),
        "*"
      );

      // >= v1.0.0
      window.parent.postMessage(
        JSON.stringify({
          message: "complete",
          payload: {
            status: "saved",
            flowSession: flowSession ? JSON.parse(flowSession) : undefined,
          },
        }),
        "*"
      );
    }
  };

  const handleDeclineOfferGroup = async () => {
    if (!finalStep || !offerStepId || !offerGroup) {
      throw new Error();
    }

    const thisStepIndex = steps.findIndex((s) => s.id === offerStepId);
    const thisStep = steps[thisStepIndex];
    const nextStep = steps[thisStepIndex + 1];

    const subscriberFlowToken = !stripeSubscriptionId
      ? token
      : initializeSubscriberFlowResult.data?.initializeSubscriberFlow.token;

    if (!subscriberFlowToken) {
      throw new Error("No subscriber flow token");
    }

    if (!testMode) {
      await declineOfferGroup({
        variables: {
          input: {
            subscriberFlowToken,
            offerGroupId: offerGroup.id,
            flowStepToken: thisStep.id,
          },
        },
      });

      await completeFlowStep({
        variables: {
          input: { subscriberFlowToken, flowStepToken: thisStep.id },
        },
      });
    }

    if (thisStep.id === finalStep.id) {
      handleCancel();
      return;
    }

    setOfferModalIsOpen(false);
    setOfferGroupModalIsOpen(false);
    // Just long enough for the modal close transition to finish.
    setTimeout(() => {
      setOffer(null);
      setNextOrderDate(undefined);
      setPauseReasons(undefined);
      setOfferGroup(null);
    }, 210);

    if (nextStep.type === "offerRuleGroup") {
      setTimeout(() => {
        handleOfferStep(nextStep.id);
      }, 220);
      return;
    }

    setCurrentStepIndex(thisStepIndex + 1);
    setNextButtonEnabled(true);
  };

  const handleDeclineOffer = async () => {
    if (!finalStep || !offerStepId || !offer) {
      throw new Error();
    }

    const thisStepIndex = steps.findIndex((s) => s.id === offerStepId);
    const thisStep = steps[thisStepIndex];
    const nextStep = steps[thisStepIndex + 1];

    const subscriberFlowToken = !stripeSubscriptionId
      ? token
      : initializeSubscriberFlowResult.data?.initializeSubscriberFlow.token;

    if (!subscriberFlowToken) {
      throw new Error("No subscriber flow token");
    }

    if (!testMode) {
      await declineOffer({
        variables: {
          input: { subscriberFlowToken, offerId: offer.id },
        },
      });

      await completeFlowStep({
        variables: {
          input: { subscriberFlowToken, flowStepToken: thisStep.id },
        },
      });
    }

    if (thisStep.id === finalStep.id) {
      handleCancel();
      return;
    }

    if (offer.style === "modal") {
      setOfferModalIsOpen(false);
      setOfferGroupModalIsOpen(false);
    }

    // Just long enough for the modal close transition to finish.
    setTimeout(() => {
      setOffer(null);
      setNextOrderDate(undefined);
      setPauseReasons(undefined);
    }, 210);

    if (nextStep.type === "offerRuleGroup") {
      setTimeout(() => {
        handleOfferStep(nextStep.id);
      }, 220);
      return;
    }

    setCurrentStepIndex(thisStepIndex + 1);
    setNextButtonEnabled(true);
  };

  const handleAbort = () => {
    if (currentStep.type === "confirmation") {
      handleConfirm();
    } else {
      handleClose("incomplete");
    }
  };

  const handleDeflect = async (deflectionId: number, flowActionId: number) => {
    const subscriberFlowToken = !stripeSubscriptionId
      ? token
      : initializeSubscriberFlowResult.data?.initializeSubscriberFlow.token;

    if (!subscriberFlowToken) {
      throw new Error("No flow token");
    }

    const result = await clickFlowAction({
      variables: {
        input: {
          subscriberFlowToken,
          flowStepToken: currentStep.id,
          deflectionId,
          flowActionId,
          testMode,
        },
      },
    });

    if (!testMode) {
      await completeFlowStep({
        variables: {
          input: { subscriberFlowToken, flowStepToken: currentStep.id },
        },
      });
    }

    if (result.data?.clickFlowAction.newSubscriberFlowToken) {
      setIsLoading(true);

      if (majorVersion && majorVersion >= 2) {
        window.parent.postMessage(
          {
            message: "loading",
          },
          "*"
        );
      }

      const url = new URL(window.location.href);
      window.location.href = `${url.origin}/p/flow/${result.data.clickFlowAction.newSubscriberFlowToken}${url.search}`;
      return;
    }

    handleClose("deflected");
  };

  const handleConfirm = () => {
    window.parent.postMessage(
      {
        message: "closed",
      },
      "*"
    );
  };

  const handleClose = async (
    status: "incomplete" | "deflected" | "already_canceled" | "cannot_cancel"
  ) => {
    if (status === "cannot_cancel") {
      const payload = {
        status: "prevented",
      };

      if (testMode || (majorVersion && majorVersion >= 2)) {
        window.parent.postMessage(
          {
            message: "complete",
            close: true,
            payload,
          },
          "*"
        );
      } else {
        // < v1.0.0
        window.parent.postMessage(JSON.stringify({ message: "aborted" }), "*");

        // >= v1.0.0
        window.parent.postMessage(
          JSON.stringify({
            message: "complete",
            payload,
          }),
          "*"
        );
      }
      return;
    }

    if (status === "already_canceled") {
      const payload = {
        status: "aborted",
      };

      if (testMode || (majorVersion && majorVersion >= 2)) {
        window.parent.postMessage(
          {
            message: "complete",
            close: true,
            payload,
          },
          "*"
        );
      } else {
        // < v1.0.0
        window.parent.postMessage(JSON.stringify({ message: "aborted" }), "*");

        // >= v1.0.0
        window.parent.postMessage(
          JSON.stringify({
            message: "complete",
            payload,
          }),
          "*"
        );
      }
      return;
    }

    const subscriberFlowToken = !stripeSubscriptionId
      ? token
      : initializeSubscriberFlowResult.data?.initializeSubscriberFlow.token;

    if (!subscriberFlowToken) {
      throw new Error();
    }

    let flowSession: string | undefined = undefined;

    if (!testMode) {
      const result = await completeSubscriberFlow({
        variables: {
          input: {
            token: subscriberFlowToken,
            status:
              status === "deflected"
                ? CompleteSubscriberFlowStatus.deflected
                : CompleteSubscriberFlowStatus.incomplete,
          },
        },
      });

      flowSession = result.data?.completeSubscriberFlow.flowSession;
    } else {
      flowSession = JSON.stringify(testModeSession(status));
    }

    if (testMode || (majorVersion && majorVersion >= 2)) {
      const payload: Record<string, any> = {
        status,
        flowSession: flowSession ? JSON.parse(flowSession) : undefined,
      };

      if (redirectUri) {
        payload.redirectUri = redirectUri;
      }

      window.parent.postMessage(
        {
          message: "complete",
          close: true,
          payload,
        },
        "*"
      );
    } else {
      // < v1.0.0
      window.parent.postMessage(JSON.stringify({ message: "aborted" }), "*");

      // >= v1.0.0
      window.parent.postMessage(
        JSON.stringify({
          message: "complete",
          payload: {
            status,
            flowSession: flowSession ? JSON.parse(flowSession) : undefined,
          },
        }),
        "*"
      );
    }
  };

  const handleSetStep = useCallback(
    (id: string) => {
      setCurrentStepIndex(steps.findIndex((s) => s.id === id));
    },
    [steps]
  );

  const handleConfirmationStep = useCallback(
    (stepId: string) => {
      setSubmitting(false);
      setNextButtonEnabled(true);
      handleSetStep(stepId);
    },
    [handleSetStep]
  );

  const handleQuestionValueUpdate = (updatedAnswer: QuestionAnswer) => {
    const values = cloneDeep(questionAnswers);
    const index = values.findIndex((answer) => answer.id === updatedAnswer.id);
    if (index > -1) {
      values[index] = updatedAnswer;
    } else {
      values.push(updatedAnswer);
    }

    setQuestionAnswers(values);
  };

  const handleCancel = useCallback(async () => {
    const subscriberFlowToken = !stripeSubscriptionId
      ? token
      : initializeSubscriberFlowResult.data?.initializeSubscriberFlow.token;

    if (!subscriberFlowToken) {
      throw new Error();
    }

    let flowSession: string | undefined = undefined;

    if (!testMode) {
      try {
        const result = await completeSubscriberFlow({
          variables: {
            input: {
              token: subscriberFlowToken,
              status: CompleteSubscriberFlowStatus.canceled,
            },
          },
        });

        flowSession = result.data?.completeSubscriberFlow.flowSession;
      } catch (e) {
        if (e instanceof ApolloError) {
          const firstError = e.graphQLErrors[0];
          if (firstError?.extensions.code) {
            setOfferModalIsOpen(false);
            setOfferGroupModalIsOpen(false);
            setCancelError({
              type: "cancel",
              code: firstError.extensions.code as ErrorCode,
            });

            return;
          }

          if (!firstError) {
            throw e;
          }
        }
      }
    } else {
      flowSession = JSON.stringify(testModeSession("canceled"));
    }

    setStatus(subscriber_flow_status_enum.canceled);

    if (testMode || (majorVersion && majorVersion >= 2)) {
      let close = true;

      const confirmationStep = steps.find(
        (step) => step.type === "confirmation"
      );
      if (confirmationStep?.type === "confirmation") {
        const confirmationVersion = getFlowObjectVersion(
          confirmationStep.confirmation,
          flowVersion
        );
        if (confirmationVersion.show_on_cancel) {
          close = false;
          setOfferModalIsOpen(false);
          setOfferGroupModalIsOpen(false);
          setIsLoading(false);
          handleConfirmationStep(confirmationStep.id);
        }
      }

      const payload: Record<string, any> = {
        status: "canceled",
        flowSession: flowSession ? JSON.parse(flowSession) : undefined,
      };

      if (redirectUri) {
        payload.redirectUri = redirectUri;
      }

      window.parent.postMessage(
        {
          message: "complete",
          close,
          payload,
        },
        "*"
      );
    } else {
      // < v1.0.0
      window.parent.postMessage(
        JSON.stringify({
          message: "canceled",
        }),
        "*"
      );

      // >= v1.0.0
      window.parent.postMessage(
        JSON.stringify({
          message: "complete",
          payload: {
            status: "canceled",
            flowSession: flowSession ? JSON.parse(flowSession) : undefined,
          },
        }),
        "*"
      );
    }
  }, [
    completeSubscriberFlow,
    flowVersion,
    handleConfirmationStep,
    initializeSubscriberFlowResult.data?.initializeSubscriberFlow.token,
    majorVersion,
    redirectUri,
    steps,
    stripeSubscriptionId,
    testMode,
    testModeSession,
    token,
  ]);

  const handleInterventionStep = useCallback(
    async (stepId: string) => {
      const subscriberFlowToken = !stripeSubscriptionId
        ? token
        : initializeSubscriberFlowResult.data?.initializeSubscriberFlow.token;

      if (!subscriberFlowToken) {
        throw new Error("No flow token");
      }

      setDiscoveringOffer(true);

      const offer = testMode
        ? await discoverTestOffer(stepId)
        : await discoverOffer(stepId, subscriberFlowToken);

      if (offer?.offer) {
        logOfferPresented(offer.offer.id, subscriberFlowToken);
        setOfferStepId(stepId);
        setOffer(offer.offer);
        setBackgroundOffer(offer.offer);
        setNextOrderDate(
          offer.nextOrderDate
            ? Temporal.PlainDate.from(offer.nextOrderDate)
            : undefined
        );
        setPauseReasons(offer.pauseReasons || undefined);
      } else if (offer?.offer_group) {
        logOfferGroupPresented(offer.offer_group.id, subscriberFlowToken);
        setOfferStepId(stepId);
        setOfferGroup(offer.offer_group);
        setPauseReasons(offer.pauseReasons || undefined);
      }

      setDiscoveringOffer(false);
    },
    [
      discoverOffer,
      discoverTestOffer,
      initializeSubscriberFlowResult.data?.initializeSubscriberFlow.token,
      logOfferGroupPresented,
      logOfferPresented,
      stripeSubscriptionId,
      testMode,
      token,
    ]
  );

  const handleOfferStep = useCallback(
    async (stepId: string) => {
      debugLog(`Handling offer step ID: ${stepId}`);

      if (!finalStep) {
        throw new Error();
      }

      const thisStepIndex = steps.findIndex((s) => s.id === stepId);
      const nextStep = steps[thisStepIndex + 1];

      setSubmitting(true);
      setNextButtonEnabled(false);

      const subscriberFlowToken = !stripeSubscriptionId
        ? token
        : initializeSubscriberFlowResult.data?.initializeSubscriberFlow.token;

      if (!subscriberFlowToken) {
        throw new Error("No flow token");
      }

      setDiscoveringOffer(true);

      const offer = testMode
        ? await discoverTestOffer(stepId)
        : await discoverOffer(stepId, subscriberFlowToken);

      if (
        !firstOfferStepsHandled &&
        (offer?.offer?.style === "modal" || offer?.offer_group)
      ) {
        // Find the next non-offer step so we can render it behind the offer modal.
        const nextIndex = steps.findIndex((s) => s.type !== "offerRuleGroup");
        setCurrentStepIndex(nextIndex);
      }

      if (offer?.offer) {
        logOfferPresented(offer.offer.id, subscriberFlowToken);

        setOfferStepId(stepId);
        setOffer(offer.offer);
        setNextOrderDate(
          offer.nextOrderDate
            ? Temporal.PlainDate.from(offer.nextOrderDate)
            : undefined
        );
        setPauseReasons(offer.pauseReasons || undefined);

        if (offer.offer.style === "step") {
          setBackgroundOffer(offer.offer);
          setCurrentStepIndex(thisStepIndex);
          setNextButtonEnabled(true);
        }

        setDiscoveringOffer(false);

        if (offer.offer.style === "modal") {
          setOfferModalIsOpen(true);
        }

        setSubmitting(false);
        return;
      }

      if (offer?.offer_group) {
        await logOfferGroupPresented(offer.offer_group.id, subscriberFlowToken);

        setOfferStepId(stepId);
        setOffer(null);
        setOfferGroup(offer.offer_group);
        setPauseReasons(offer.pauseReasons || undefined);

        setDiscoveringOffer(false);

        setOfferGroupModalIsOpen(true);
        setSubmitting(false);
        return;
      }

      if (stepId === finalStep.id) {
        if (!testMode) {
          await completeFlowStep({
            variables: {
              input: { subscriberFlowToken, flowStepToken: stepId },
            },
          });
        }

        handleCancel();
        return;
      }

      if (nextStep.type === "offerRuleGroup") {
        handleOfferStep(nextStep.id);
        return;
      }

      setDiscoveringOffer(false);
      setCurrentStepIndex(thisStepIndex + 1);
      setSubmitting(false);
      setNextButtonEnabled(true);
    },
    [
      completeFlowStep,
      discoverOffer,
      discoverTestOffer,
      finalStep,
      handleCancel,
      initializeSubscriberFlowResult.data,
      logOfferGroupPresented,
      logOfferPresented,
      steps,
      stripeSubscriptionId,
      testMode,
      token,
      firstOfferStepsHandled,
    ]
  );

  const handleClickNext = async () => {
    if (!currentStep || !finalStep || (!finalFormStep && !finalQuestionStep)) {
      throw new Error();
    }

    setIntakeFormDisplayErrorMessage(undefined);

    const subscriberFlowToken = !stripeSubscriptionId
      ? token
      : initializeSubscriberFlowResult.data?.initializeSubscriberFlow.token;

    if (!subscriberFlowToken) {
      throw new Error("No subscriber flow token");
    }

    if (currentStep.type === "offerRuleGroup") {
      setSubmitting(true);
      setNextButtonEnabled(false);
      handleDeclineOffer();
      setSubmitting(false);
      return;
    }

    setOfferStepId(null);
    setOffer(null);
    setNextOrderDate(undefined);
    setPauseReasons(undefined);

    setDoubleClickProtectionExpired(false);
    setTimeout(() => {
      setDoubleClickProtectionExpired(true);
    }, 500);

    if (
      !testMode &&
      currentStep.type === "subscriberDetailsForm" &&
      customSubscriberDetails
    ) {
      const { name, email, ...rest } = customSubscriberDetails;

      const submitResult = await submitCustomSubscriberDetails({
        variables: {
          input: {
            subscriberFlowToken,
            name,
            email,
            answers: rest,
          },
        },
      });

      if (submitResult.data?.submitCustomSubscriberDetails.prevented) {
        setMode("already_canceled");
        return;
      } else if (submitResult.data?.submitCustomSubscriberDetails.success) {
        await refetchSegmentMatches();

        if (submitResult.data?.submitCustomSubscriberDetails.rerouted) {
          await refetchFlow();
        }

        if (flow?.account.intake_form_name_email) {
          // Refreshing subscriber details will automatically proceed to the next step.
          return;
        }
      } else {
        setIntakeFormDisplayErrorMessage(
          submitResult.data?.submitCustomSubscriberDetails.message ||
            "Something went wrong. Please check your information and try again."
        );
        return;
      }
    }

    if (currentStep.type === "form") {
      await submitFormAnswers(currentStep.id);
    }

    if (currentStep.type === "question") {
      await submitQuestionAnswer(currentStep.id);
    }

    if (currentStep.type === "confirmation") {
      handleConfirm();
      return;
    }

    if (currentStep.type === "deflectionRuleGroup" && currentStep.deflection) {
      setPresentedDeflections({
        ...presentedDeflections,
        [currentStep.id]: currentStep.deflection,
      });
    }

    if (!testMode && currentStep.type !== "subscriberDetailsForm") {
      await completeFlowStep({
        variables: {
          input: {
            subscriberFlowToken,
            flowStepToken: currentStep.id,
          },
        },
      });
    }

    if (nextStep && nextStep.type !== "confirmation") {
      if (nextStep.type === "offerRuleGroup") {
        handleOfferStep(nextStep.id);
        return;
      }

      if (nextStep.type === "intervention") {
        setCurrentStepIndex(currentStepIndex + 1);
        handleInterventionStep(nextStep.id);
        return;
      }

      setCurrentStepIndex(currentStepIndex + 1);
      return;
    }

    if (currentStep.id === finalStep.id) {
      setSubmitting(true);
      setNextButtonEnabled(false);
      handleCancel();
    }
  };

  useEffect(() => {
    if (!currentStepIndex || currentStepIndex === 0) {
      return;
    }
    setStepTransitioning(true);
    setTimeout(() => setStepTransitioning(false), transitionDuration);
  }, [currentStepIndex]);

  const flowText = mapFlowText(flow?.flow_texts || [], flowVersion);

  const stepElement = renderStep({
    isEditMode: false,
    flowToken: token || "",
    flow,
    currentStep,
    onAcknowledgementsChanged: setAcknowledged,
    submitting,
    onQuestionValueUpdate: handleQuestionValueUpdate,
    enabledLanguages,
    onDeflect: handleDeflect,
    onCustomSubscriberDetailsChanged: setCustomSubscriberDetails,
    onGoToNextStep: handleClickNext,
    onCancel: () => {
      setIsLoading(true);
      handleCancel();
    },
    offer:
      currentStep?.type === "intervention"
        ? backgroundOffer
        : backgroundOffer || offer,
    nextOrderDate,
    pauseReasons,
    onAcceptOffer: handleAcceptOffer,
    flowVersion,
    flowText,
    isFreeMode,
    onClickUpgrade: () => handleClickUpgrade(true),
    intakeFormDisplayErrorMessage,
    swappableProducts,
    acceptOfferError,
  });

  useEffect(() => {
    if (env("NODE_ENV") === "development") {
      document.domain = "localhost";
      // Stupid hack for Vulcan
      // Can remove if we switch to a headless browser
      (window as any).__VULCAN__ = {
        change: (node: Element) => ReactTestUtils.Simulate.change(node),
      };
    }
  }, []);

  useEffect(() => {
    // Handle the first steps being offers.
    if (
      currentStep &&
      currentStep.type === "offerRuleGroup" &&
      !firstOfferStepsHandled
    ) {
      handleOfferStep(currentStep.id);
      setFirstOfferStepsHandled(true);
      return;
    }

    // Handle the first step being an intervention.
    if (
      currentStep &&
      currentStep.type === "intervention" &&
      !firstOfferStepsHandled
    ) {
      handleInterventionStep(currentStep.id);
      setFirstOfferStepsHandled(true);
      return;
    }

    if (currentStep && !firstOfferStepsHandled) {
      setFirstOfferStepsHandled(true);
    }
  }, [
    currentStep,
    currentStepIndex,
    firstOfferStepsHandled,
    handleInterventionStep,
    handleOfferStep,
    steps,
    discoveringOffer,
    offer,
  ]);

  useEffect(() => {
    if (
      firstStepReady ||
      !firstOfferStepsHandled ||
      !currentStep ||
      discoveringOffer
    ) {
      return;
    }

    if (currentStep.type === "offerRuleGroup") {
      if (offer) {
        setFirstStepReady(true);
      }
      return;
    }

    setFirstStepReady(true);
  }, [
    firstStepReady,
    firstOfferStepsHandled,
    offer,
    backgroundOffer,
    offerModalIsOpen,
    currentStep,
    discoveringOffer,
  ]);

  const numStepsAfterThisOne = steps
    .slice(currentStepIndex + 1)
    .filter(
      (step) => step.type !== "offerRuleGroup" && step.type !== "confirmation"
    ).length;

  const isFinalButton =
    currentStep?.type !== "confirmation" &&
    (currentStepIndex === steps.length - 1 || numStepsAfterThisOne === 0);

  let isFinalOfferButton = isFinalButton;

  // When an offer is shown before the first step, which is rendered in the background.
  if (!!offerStepId) {
    const offerStepIndex = steps.findIndex((step) => step.id === offerStepId);
    if (offerStepIndex < currentStepIndex) {
      isFinalOfferButton = false;
    }
  }

  // TODO
  // const subscriberSegmentMatches =
  //   env('NODE_ENV') === 'development'
  //     ? flow?.subscriber_matching_segments || []
  //     : [];
  const subscriberSegmentMatches: any[] = [];

  const cssValue =
    (previewMode && previewCss !== null ? previewCss : version?.css) || "";
  const globalCssValue =
    (previewMode && previewGlobalCss !== null
      ? previewGlobalCss
      : flow?.account.flow_css) || "";

  const allowNext = useMemo(() => {
    if (!currentStep) {
      return false;
    }

    switch (currentStep.type) {
      case "subscriberDetailsForm":
        return !!customSubscriberDetails;

      case "acknowledgementGroup":
        return acknowledged;

      case "form":
        return (
          !currentStep.questions.length ||
          currentStep.questions.every(
            (q) => !q.isEnabled || q.isHidden || q.isSatisfied
          )
        );

      case "question":
        return questionIsSatisfied(
          currentStep.question,
          questionAnswers,
          flowVersion
        );

      default:
        return true;
    }
  }, [
    acknowledged,
    currentStep,
    customSubscriberDetails,
    flowVersion,
    questionAnswers,
  ]);

  const companyName = flow?.account.title || "";

  const showBranding =
    (flow?.account.show_branding_feature.length &&
      flow.account.show_branding_feature[0].value) ||
    false;

  const containerProps = {
    testMode,
    previewMode,
    editorMode: false,
    currentStepIndex,
    steps,
    totalSteps: steps.length,
    currentStep,
    offer,
    nextOrderDate,
    pauseReasons,
    offerGroup,
    swappableProducts,
    loading: isLoading,
    stepElement,
    nextButtonEnabled:
      allowNext &&
      nextButtonEnabled &&
      doubleClickProtectionExpired &&
      !stepTransitioning,
    isFinalButton,
    isFinalOfferButton,
    submitting,
    pagingDisabled: true,
    logoUrl: flow?.logo_url || undefined,
    companyName,
    showBranding,
    globalCssValue,
    cssValue,
    subscriberSegmentMatches,
    flowText,
    status,
    onSetStep: handleSetStep,
    onClickNext: handleClickNext,
    onAcceptOffer: handleAcceptOffer,
    onAcceptGroupOffer: handleAcceptGroupOffer,
    onDeclineOffer: handleDeclineOffer,
    onDeclineOfferGroup: handleDeclineOfferGroup,
    onAbort: handleAbort,
    onDeflect: handleDeflect,
    onUpdateText: () => {},
    offerModalIsOpen,
    offerGroupModalIsOpen,
    presentedOfferStepIds,
    displayMode,
    isFreeMode,
    onClickUpgrade: () => handleClickUpgrade(true),
    acceptOfferError,
    cancelError,
    eligibilityMessageHeader: subscriberFlow?.eligibility_message
      ?.eligibility_header_translation
      ? translationValue(
          subscriberFlow.eligibility_message.eligibility_header_translation,
          language,
          defaultLanguage
        ).value
      : undefined,
    eligibilityMessage: subscriberFlow?.eligibility_message
      ?.eligibility_message_translation
      ? translationValue(
          subscriberFlow.eligibility_message.eligibility_message_translation,
          language,
          defaultLanguage
        ).value
      : undefined,
  };

  return (
    <>
      <Helmet>
        <title>{companyName && `Cancel subscription - ${companyName}`}</title>
      </Helmet>
      <FlowVersionProvider version={flowVersion}>
        <TranslationsProvider
          language={language}
          defaultLanguage={defaultLanguage}
          enabledLanguages={enabledLanguages}
        >
          <PropertyValuesProvider
            propertyValues={
              previewMode ? previewPropertyValues : propertyValues
            }
            propertyConfig={propertyConfig}
          >
            {mode === "already_canceled" ? (
              <AlreadyCanceled
                content={
                  JSON.stringify(flow?.prevent_if_canceled_translation) || ""
                }
                isTranslatable={true}
                onClose={() => handleClose("already_canceled")}
                isLoading={isLoading}
              />
            ) : mode === "cannot_cancel" &&
              !subscriberFlow?.eligibility_message ? (
              <AlreadyCanceled
                content="Your subscription is unable to be canceled at this time. Please contact support."
                isTranslatable={false}
                onClose={() => handleClose("cannot_cancel")}
                isLoading={isLoading}
              />
            ) : (
              <FlowContent {...containerProps} />
            )}
          </PropertyValuesProvider>
        </TranslationsProvider>
      </FlowVersionProvider>
    </>
  );
};

export default Flow;
