import gql from "graphql-tag";

import FlowHasUnsavedChangesOfferRuleGroupFragment from "./FlowHasUnsavedChangesOfferRuleGroupFragment";
import FlowHasUnsavedChangesQuestionFragment from "./FlowHasUnsavedChangesQuestionFragment";

export default gql`
  fragment FlowHasUnsavedChangesFlowFragment on flow {
    id
    published_version {
      id
      flow_version_flow_steps(order_by: { position: asc }) {
        flow_step {
          id
          type
          draft_version {
            id
          }
          flow_step_acknowledgement_group {
            flow_step_id
            acknowledgement_group_id
            acknowledgement_group {
              id
              published_version {
                id
                acknowledgement_group_version_acknowledgements {
                  acknowledgement {
                    id
                    draft_version {
                      id
                    }
                  }
                }
              }
              draft_version {
                id
              }
            }
          }
          flow_step_confirmation {
            flow_step_id
            confirmation_id
            confirmation {
              id
              draft_version {
                id
              }
            }
          }
          flow_step_deflection_rule_group {
            flow_step_id
            offer_rule_group_id
            offer_rule_group {
              ...FlowHasUnsavedChangesOfferRuleGroupFragment
            }
          }
          flow_step_form {
            flow_step_id
            form_id
            form {
              id
              published_version {
                id
                form_version_questions(order_by: { position: asc }) {
                  question {
                    ...FlowHasUnsavedChangesQuestionFragment
                  }
                }
              }
              draft_version {
                id
              }
            }
          }
          flow_step_intervention {
            flow_step_id
            intervention_id
            intervention {
              id
              draft_version {
                id
              }
              offer_rule_group {
                ...FlowHasUnsavedChangesOfferRuleGroupFragment
              }
            }
          }
          flow_step_offer_rule_group {
            flow_step_id
            offer_rule_group_id
            offer_rule_group {
              ...FlowHasUnsavedChangesOfferRuleGroupFragment
            }
          }
          flow_step_question {
            flow_step_id
            question_id
            question {
              ...FlowHasUnsavedChangesQuestionFragment
            }
          }
        }
      }
    }
    draft_version {
      id
    }
    flow_texts {
      flow_id
      key
      published_version {
        id
      }
      draft_version {
        id
      }
    }
  }
  ${FlowHasUnsavedChangesOfferRuleGroupFragment}
  ${FlowHasUnsavedChangesQuestionFragment}
`;
