import {
  Control,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form/dist/types";

import FieldInput from "../../common/form/FieldInput";
import FieldLabel from "../../common/form/FieldLabel";
import FieldRow from "../../common/form/FieldRow";
import SelectInput from "../../common/form/input/SelectInput";
import { OfferFormValues } from "../offers/forms/types";
import ConversionOfferTimeMode from "./ConversionOfferTimeMode";

interface ConversionOfferFormPartialProps {
  register: UseFormRegister<any>;
  watch: UseFormWatch<OfferFormValues>;
  control: Control<any, any>;
  setValue: UseFormSetValue<any>;
  isDirty: boolean;
}

const ConversionOfferFormPartial: React.FunctionComponent<
  ConversionOfferFormPartialProps
> = ({ register, watch, control, setValue, isDirty = false }) => (
  <>
    <FieldRow>
      <FieldLabel>
        <label htmlFor="style">Style</label>
      </FieldLabel>
      <FieldInput>
        <SelectInput {...register("style")} id="style" defaultValue="modal">
          <option value="modal">Modal</option>
          <option value="banner">Banner</option>
        </SelectInput>
      </FieldInput>
    </FieldRow>
    <ConversionOfferTimeMode
      register={register}
      watch={watch}
      control={control}
      setValue={setValue}
      isDirty={isDirty}
    />
  </>
);

export default ConversionOfferFormPartial;
