import { faGifts, faPlaneUp, faVial } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  FlowOfferGroupFragment,
  FlowOfferRuleOfferFragment,
  FlowOfferTestFragment,
} from "../../../../__generated__/graphql";
import { OfferAutopilotBox } from "../../../../common/rules/RuleBoxes";
import {
  NoOfferBox,
  OfferBox,
  OfferGroupBox,
  OfferTestBox,
} from "../../../../common/rules/RuleBoxes";
import offerIcon from "../../../offers/lib/offerIcon";

interface RuleRule {
  offerIds: number[];
  offerTestIds: number[];
  offerGroupIds: number[];
  offerAutopilotOfferIds: number[];
  deflectionIds: number[];
  includePresentNoOffer: boolean;
}

interface OfferRuleOffersProps {
  rule: RuleRule;
  offers: FlowOfferRuleOfferFragment[];
  offerTests: FlowOfferTestFragment[];
  offerGroups: FlowOfferGroupFragment[];
  offerAutopilotOffers: FlowOfferRuleOfferFragment[];
}

const OfferRuleOffers: React.FunctionComponent<OfferRuleOffersProps> = ({
  rule,
  offers,
  offerTests,
  offerGroups,
  offerAutopilotOffers,
}) => (
  <div tw="flex flex-wrap -m-1">
    {(rule.includePresentNoOffer ||
      (!rule.offerIds.length &&
        !rule.offerTestIds.length &&
        !rule.offerGroupIds.length &&
        !rule.offerAutopilotOfferIds.length &&
        !rule.deflectionIds.length)) && (
      <NoOfferBox
        key="any"
        isOn={rule.includePresentNoOffer}
        isReallyOn={
          !rule.offerIds.length &&
          !rule.offerTestIds.length &&
          !rule.offerGroupIds.length &&
          !rule.offerAutopilotOfferIds.length &&
          !rule.deflectionIds.length
        }
        readOnly
      >
        Present no offer
      </NoOfferBox>
    )}
    {offers.map((offer) => {
      const isOn = rule.offerIds.includes(offer.id);

      if (!isOn) {
        return null;
      }

      return (
        <OfferBox key={offer.id} isOn={true} readOnly>
          <FontAwesomeIcon
            icon={offerIcon(offer.type)}
            transform="shrink-3"
            tw="mr-1"
          />{" "}
          {offer.name}
        </OfferBox>
      );
    })}
    {offerTests.map((offerTest) => {
      const isOn = rule.offerTestIds.includes(offerTest.id);

      if (!isOn) {
        return null;
      }

      return (
        <OfferTestBox key={offerTest.id} isOn={true} readOnly>
          <FontAwesomeIcon icon={faVial} transform="shrink-3" />{" "}
          {offerTest.name}
        </OfferTestBox>
      );
    })}
    {offerGroups.map((offerGroup) => {
      const isOn = rule.offerGroupIds.includes(offerGroup.id);

      if (!isOn) {
        return null;
      }

      return (
        <OfferGroupBox key={offerGroup.id} isOn={true} readOnly>
          <FontAwesomeIcon icon={faGifts} transform="shrink-3" />{" "}
          {offerGroup.name}
        </OfferGroupBox>
      );
    })}
    {offerAutopilotOffers.map((autopilotOffer) => {
      const isOn = rule.offerAutopilotOfferIds.includes(autopilotOffer.id);

      if (!isOn) {
        return null;
      }

      return (
        <OfferAutopilotBox
          key={autopilotOffer.id}
          isEditable={false}
          isOn={true}
        >
          <FontAwesomeIcon icon={faPlaneUp} transform="shrink-3" />{" "}
          {autopilotOffer.name}
        </OfferAutopilotBox>
      );
    })}
  </div>
);

export default OfferRuleOffers;
